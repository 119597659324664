import React from "react";
import Snackbar from "../alert/Snackbar";
import { OpeningSoonProvider } from "../../context/openingSoonContext";

import Navigation from "./Navigation";
import Footer from "./Footer";

const Layout = ({ children }) => {

  return (
    <OpeningSoonProvider>
      <div className="bg-gradient-to-b from-[#0f151c] to-[#151A1C] scrollbar scrollbar-thumb-rose-500">
        <Navigation />
          {children}
        <Footer />
        <Snackbar/>
      </div>
    </OpeningSoonProvider>
  );
};

export default Layout;
