import { useEffect, useRef } from "react";

function Content({ desc, short }) {
  const divEl = useRef(null);
  useEffect(() => {
    if (divEl.current) {
      divEl.current.innerHTML = desc;
    }
  }, [desc]);

  return (
    <div
      ref={divEl}
      className={`${short ? "mt-2 font-[300]" : ""} text-white/70 blog-text`}
    ></div>
  );
}

export default Content;
