import React from "react";
import { useTranslation } from "react-i18next";

const Counter = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto my-28 max-sm:mb-0">
      <p className="text-white text-center text-3xl md:text-5xl font-semibold max-sm:text-4xl">
        {t("about.stats.title")}
      </p>
      <p className="text-text-secondary w-[90%] md:w-[80%] lg:w-[65%] mx-auto text-center max-md:text-sm mt-4">
        {t("about.stats.description")}
      </p>

      <div className="flex justify-center mt-8 max-sm:mt-4">
        <div 
          className="
            p-8 flex max-lg:flex-col max-lg:items-center gap-[75px] rounded-2xl max-sm:w-[90%] max-sm:gap-8 border-4 border-[#22262A] 
            hover:bg-gradient-to-b from-[#22262A] to-[#1A2024] transition-all
          "
        >
          <div>
            <p className="bg-clip-text bg-gradient-to-l font-bold text-transparent from-custom-primary to-custom-primary-darker text-[54px] max-sm:text-[45px] max-lg:text-center">
              50k
            </p>
            <p className="text-lg max-lg:text-center text-white/80 font-semibold">
              {t("about.stats.table.worldwide")}
            </p>
          </div>
          <div>
            <p className="bg-clip-text bg-gradient-to-l font-bold text-transparent from-custom-primary to-custom-primary-darker text-[54px] max-sm:text-[45px] max-lg:text-center">
              10M+
            </p>
            <p className="text-lg max-lg:text-center text-white/80 font-semibold">
              {t("about.stats.table.payments")}
            </p>
          </div>
          <div>
            <p className="bg-clip-text bg-gradient-to-l font-bold text-transparent from-custom-primary to-custom-primary-darker text-[54px] max-sm:text-[45px] max-lg:text-center">
              20+
            </p>
            <p className="text-lg max-lg:text-center text-white/80 font-semibold">
              {t("about.stats.table.countries")}
            </p>
          </div>
          <div>
            <p className="bg-clip-text bg-gradient-to-l font-bold text-transparent from-custom-primary to-custom-primary-darker text-[54px] max-sm:text-[45px] max-lg:text-center">
              100+
            </p>
            <p className="text-lg max-lg:text-center text-white/80 font-semibold">
              {t("about.stats.table.currencies")}
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Counter;
