import React from "react";
import CoinCard from "./CoinCard";
import axios from "axios";
import { useEffect, useState } from "react";

const Bonuses = () => {

  const [coinsData, setCoinsData] = useState([]);

    useEffect(() => {
      axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd")
        .then((response) => {
          const data = response.data;
          setCoinsData(data);
        })
        .catch(error => {
          console.log(error);
        });
    }, []);

  return (
    <div className="my-36 container py-[77px] px-4 max-sm:py-0 max-sm:px-2 max-sm:my-12">
      <div className="text-center mb-[30px]">
        <p className="text-white font-semibold text-[42px] mb-6 tracking-[-1.68px] leading-[52px] max-sm:text-4xl max-sm:mb-3">
          Collect digital currency bonuses.
        </p>
        <p
          className="text-white max-w-[675px] m-auto tracking-[-0.64px] leading-[21px] max-sm:text-sm"
          style={{ color: "var(--gray-400, #98A2B3)" }}
        >
          You can get up to 24% in yearly rewards by staking your assets with
          Easy Crypto 365. Staking is as easy as a few simple clicks.*
        </p>
      </div>

      <div className="flex justify-between max-w-[1015px] m-auto mb-[30px] max-xl:gap-[20px] max-lg:flex-col max-lg:items-center max-lg:mb-[40px]">
          {coinsData.slice(0, 5).map((coin, indx) => (
            <CoinCard
              coin={coin?.name}
              icon={coin?.image}
              abr={coin?.symbol.toUpperCase()}
              sum={coin?.current_price}
              status={coin?.price_change_percentage_24h}
              positive={coin?.price_change_percentage_24h}
            />
          ))}
        </div>
      <div className="flex justify-between max-w-[1015px] m-auto max-xl:gap-[20px] max-lg:flex-col max-lg:items-center">
        {coinsData.slice(5, 10).map((coin, indx) => (
          <CoinCard
            coin={coin?.name}
            icon={coin?.image}
            abr={coin?.symbol.toUpperCase()}
            sum={coin?.current_price}
            status={coin?.price_change_percentage_24h}
            positive={coin?.price_change_percentage_24h}
          />
        ))}
      </div>
    </div>
  );
};

export default Bonuses;
