import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { v4 } from "uuid";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { fetcherGET } from "../../lib/swr/fetcher";
import { useDispatch } from "react-redux";
import { changeMessages } from "../../store/slice";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  const searchDropDown = useRef(null);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  let filterTimeout;
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_BACKEND_URI}/articles/search/${
      value.length ? value : "empty"
    }`,
    fetcherGET
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function ifError() {
    if (error) {
      dispatch(
        changeMessages({
          message: "Make sure your internet connection is good",
          title: "Error",
          status: "error",
        })
      );
    }
    return (
      <div className="flex flex-col w-full justify-center items-center h-full">
        <img
          className="logoFloat w-[70px]"
          src="/niza-logo-custom-color.svg"
          alt=""
        />
        <p className="text-center">{t("loading")}...</p>
      </div>
    );
  }

  const doCityFilter = (query) => {
    clearTimeout(filterTimeout);
    if (!query) return setValue("");
    filterTimeout = setTimeout(() => {
      setValue(query);
    }, 400);
  };

  function handleClickOutside(event) {
    if (
      searchDropDown.current &&
      !searchDropDown.current.contains(event.target)
    ) {
      setOpen(false);
    }
  }

  function startSearching() {
    setOpen(true);
    doCityFilter(value);
  }

  function CheckStatus() {
    if (isLoading || error) {
      return ifError();
    } else if (data) {
      if (data.length === 0) {
        return (
          <div className="text-center flex flex-col items-center justify-center h-full">
            <img
              src="/icons/searching_icon.svg"
              className="w-[100px] mb-10 logoFloat"
              alt=""
            />
            <p>Write something to activate the search functionality</p>
          </div>
        );
      } else if (!data.length) {
        return (
          <div className="text-center flex flex-col items-center justify-center h-full">
            <img
              src="/icons/no_result.png"
              alt=""
              className="w-[70px] mb-10 logoFloat"
            />
            <p>No result!</p>
          </div>
        );
      } else {
        return (
          <>
            {data.map((article, indx) => (
              <div
                key={v4()}
                className={`flex items-start gap-3 py-5 ${
                  indx !== data.length - 1 ? "border-b border-black/10" : ""
                }`}
              >
                <img
                  className="text-black w-[30px] md:w-[35px]"
                  src={`/icons/${article.category
                    .replace(" ", "_")
                    .toLowerCase()}_article_black.svg`}
                  alt={article.category}
                />
                <div>
                  <p className="text-black max-md:text-sm">{article.title}</p>
                  <Link to={`/support/${article._id}`}>
                    <p className="flex items-center gap-1 text-custom-primary-dark hover:text-custom-primary-dark/90 transition-colors cursor-pointer max-md:text-sm">
                      {t("support.readArticle")}{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[10px]"
                      />
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </>
        );
      }
    }
  }

  return (
    <div className="h-[750px] bg-[#141A20] relative fade-bottom">
      <div
        style={{ backgroundImage: `url("/heroBg.png")` }}
        className="h-full w-full opacity-[0.7] mix-blend-overlay"
      ></div>
      <div className="z-[10] absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div>
          <p className="text-3xl md:text-4xl font-bold uppercase text-white text-center mb-2">
          {t("support.hero.title")}
          </p>
          <p className="text-base md:text-xl font-[300] text-white/80 text-center mb-6">
          {t("support.hero.description")}
          </p>
          <div
            className={`flex items-center bg-white ${
              open ? "rounded-t-xl" : "rounded-full"
            } relative`}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} className="pl-5" />
            <input
              type="text"
              placeholder={`${t("search")}...`}
              className="py-2 px-4 m-1 outline-none w-[200px] sm:w-[300px] md:w-[500px]"
              onChange={(event) => doCityFilter(event.target.value)}
              onFocus={startSearching}
            />
            <div
              className={`bg-custom-primary ${
                open ? "rounded-tr-xl" : "rounded-r-full"
              } py-3 px-4 cursor-pointer hover:bg-custom-primary-dark transition-colors font-[500]`}
              onClick={startSearching}
            >
              <p>{t("search")}</p>
            </div>
            {open ? (
              <div
                className="absolute bg-white top-[102%] w-full  rounded-b-xl"
                ref={searchDropDown}
              >
                <div className="h-[350px] max-h-[350px] md:h-[450px] md:max-h-[450px] p-5  overflow-scroll">
                  {CheckStatus()}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
