export const carouselData = [
  {
    title: 'Real-Time Market Data',
    description: 'Stay ahead with real-time market updates and data analytics.',
    link: "#",
    type: "gray",
  },
    
  {
    title: 'Secure Trading Environment',
    description: 'Your security is our priority.',
    link: "#",
    type: "green",
  },
    
  {
    title: 'User-Friendly Interface',
    description: 'Trading made simple with our intuitive and easy-to-navigate interface.',
    link: "#",
    type: "gray",
  },
    
  {
    title: 'Low Transaction Fees',
    description: 'Maximize your profits with our competitive and low transaction fees.',
    link: "#",
    type: "green",
  },
    
  {
    title: 'Advanced Trading Tools',
    description: 'Enhance your trading strategies with our advanced tools and features.',
    link: "#",
    type: "gray",
  },
  {
    title: '24/7 Customer Support',
    description: 'We\'re here for you around the clock.',
    link: "#",
    type: "green",
  },
    
  {
    title: 'Diverse Crypto Selection',
    description: 'Trade a wide range of cryptocurrencies.',
    link: "#",
    type: "gray",
  },
    
  {
    title: 'Customizable Alerts',
    description: 'Never miss an opportunity with customizable price and market alerts.',
    link: "#",
    type: "green",
  }
    
]