export const aml = [
    {
      title: "INTRODUCTION",
      description: `The purpose of these Guidelines for Anti-Money Laundering (AML), Combating Terrorist Financing 
        (CFT) and Sanctions measures is to ensure that: SmolEnterprises OU (Company) has internal guidelines 
        to prevent the use of its business for Money Laundering and Terrorist Financing and internal guidelines 
        for implementation of international sanctions .
        <br/><br/>
        These Guidelines have been adopted to ensure that the Company complies with the rules and 
        regulations set out in the Measures against Money Laundering Act (the “MAMLA”) and the Combating 
        Terrorism Financing Measures Act (CTF Act), DIRECTIVE (EU) 2015/849 and Directive (EU) 
        2018/843 and other applicable legislation.
        <br/><br/>
        These Guidelines are subject to a review by the Management Board at least annually. Proposal for a 
        review and the review of these Guidelines may be scheduled more frequently by the decision of the 
        Company’s Money Laundering Reporting Officer (MLRO).
        <br/><br/>
        These Guidelines shall be accepted and approved by the resolution of the Company’s Management Board.
      `
    },
    {
      title: "DEFINITIONS",
      description: `<b>Beneficial Owner</b> means a natural person who, taking advantage of their influence, makes a 
        transaction, act, action, operation or step or exercises control in another manner over a transaction, act, 
        action, operation or step or over another person and in whose interests or for whose benefit or on whose 
        account a transaction or act, action, operation or step is made. In the case of a legal entity, the Beneficial 
        Owner is a natural person whose direct or indirect holding, or the sum of all direct and indirect holdings 
        in the legal person, exceeds 25 percent, including holdings in the form of shares or other forms of bearer.
        <br/><br/>
        <b>Business relationship</b> shall mean a business, professional or commercial relationship between a 
        customer and financial institutions or other obliged entities which are connected with their professional 
        activities and which is expected, at the time when the contact is established, to have an element of 
        duration.
        <br/><br/>
        <b>Company</b> means legal entity with following data:
        <ul className="list-disc list-inside">
            <li>company name: SmolEnterprises OU;</li>
            <li>registration country: Estonia;</li>
            <li>registration number: 16670458;</li>
            <li>address: Harju maakond, Tallinn, Kesklinna linnaosa, Tornimäe tn 7-26, 10145;</li>
            <li>email: <a className="underline" href="mailto:smolenterprisesou@gmail.com">smolenterprisesou@gmail.com</a></li>
        </ul>
        <br/>
        <b>Custodian Virtual Currency Wallet</b> means Virtual Currency Address(es) generated with the public 
        key for storing and managing Virtual Currencies entrusted to the Company but remaining their property.
        <br/><br/>
        <b>Customer</b> means a natural person or a legal entity which has the Business Relationship with the 
        Company or a natural person or legal entity with which the Company enters into the Occasional 
        Transaction.
        <br/><br/>
        <b>Employee</b>means the Company´s employee and any other person who is involved in application of 
        these Guidelines in the Company.
        <br/><br/>
        <b>Guidelines</b> – this document including all annexes as provided above. The Guidelines include inter 
        alia the Company’s internal control procedure regarding the Guidelines and the Company’s risk 
        assessment policy regarding risk-based approach for ML/TF risks.        
        <br/><br/>
        <b>Management Board</b>means management board of the Company. If the Company has no management 
        board – the manager of the Company shall be considered as the Management Board member and he or 
        she shall be responsible for the Management Board duties in the context of the Guidelines.
        <br/><br/>
        <b>MLRO</b> means Money Laundering Reporting Officer, who is appointed to the Company as a person 
        responsible for receiving internal disclosures and making reports to the SANS and other duties as 
        described above.
        <br/><br/>
        <b>Monetary Operation</b> means any payment, transfer or receipt of money.
        <br/><br/>
        <b>Money Laundering (ML)</b> means the concealment of the origins of illicit funds through their 
        introduction into the legal economic system and transactions that appear to be legitimate. There are three 
        recognized stages in the Money Laundering process:
        <ul className="list-disc list-inside">
            <li>placement, which involves placing the proceeds of crime into the financial system;</li>
            <li>layering, which involves converting the proceeds of crime into another form and creating complex 
                layers of financial transactions to disguise the audit trail and the source and ownership of funds;
            </li>
            <li>integration, which involves placing the laundered proceeds back into the economy to create the 
            perception of legitimacy.</li>
        </ul>
        <br/>
        <b>Occasional Transaction</b> means the transaction performed by the Company in the course of economic 
        or professional activities for the purpose of provision of a service or sale of goods or distribution thereof 
        in another manner to the Customer outside the course of an established Business Relationship.
        <br/><br/>
        <b>PEP</b> means a natural person who performs or has performed prominent public functions and with 
        regard to whom related risks remain.
        <br/><br/>
        <b>Sanctions</b> mean an essential tool of foreign policy aimed at supporting the maintenance or restoration 
        of peace, international security, democracy and the rule of law, following human rights and international 
        law or achieving other objectives of the United Nations Charter or the common foreign and security 
        Policy of the European Union. Sanctions include:
        <ul className="list-disc list-inside">
            <li>international Sanctions which are imposed with regard to a state, territory, territorial unit, regime, 
                organization, association, group or person by a resolution of the United Nations Security Council, a 
                decision of the Council of the European Union or any other legislation imposing obligations on Bulgaria;
            </li>
            <li>sanctions of the Minister of Foreign Affairs, the Minister of Interior, the Chairperson of the State 
                Agency for National Security or the Prosecutor General, the Council of Ministers which are authorised 
                to adopt, supplement and modify the list of persons to be covered by the LMFT (Law on Measures against 
                Financing of Terrorism) measures.
            </li>
        </ul>
        <br/>
        International Sanctions may ban the entry of a subject of an international Sanction in the state, restrict 
        international trade and international transactions, and impose other prohibitions or obligations.
        <br/><br/>
        The subject of Sanctions is any natural or legal person, entity, or body, designated in the legal act 
        imposing or implementing Sanctions, with regard to which the Sanctions apply.
        <br/><br/>
        <b>Terrorist Financing (TF)</b> means the financing and supporting of an act of terrorism and 
        commissioning thereof as well as the financing and supporting of travel for the purpose of terrorism in 
        the meaning of applicable legislation.
        <br/><br/>
        <b>Equivalent Third Country</b> – a country, which is not a Member State of European Economic Area 
        but applying an equivalent regime to the EU corresponding (AML) framework.        
        <br/><br/>
        <b>Virtual Currency</b> means a value represented in the digital form, which is digitally transferable, 
        preservable or tradable and which natural persons or legal persons accept as a payment instrument, but 
        that is not the legal tender of any country or funds for the purposes of Article 4(25) of Directive (EU) 
        2015/2366 of the European Parliament and of the Council on payment services in the internal market, 
        amending Directives 2002/65/EC, 2009/110/EC and 2013/36/EU and Regulation (EU) No 1093/2010, 
        and repealing Directive 2007/64/EC (OJ L 337, 23.12.2015, pp 35–127) or a payment transaction for the 
        purposes of points (k) and (l) of Article 3 of the same Directive.
        <br/><br/>
        <b>Virtual Currency Address</b> means address/account generated from letters, numbers and/or symbols 
        in the blockchain, by which the blockchain allocates the Virtual Currency to the owner or recipient.
        <br/><br/>
        <b>SANS</b> - Bulgarian State Agency for National Security
      `
    },
    {
      title: "CUSTOMER DUE DILIGENCE MEASURES",
      description: `Customer due diligence (CDD) measures are required for verifying the identity of a new or existing
        Customer as a well-performing risk-based ongoing monitoring of the Business Relationship with the
        Customer. The CDD measures consist of 2 levels, including standard and enhanced due diligence
        measures, as specified below.
        <br/><br/>
        <b>Main Principles</b>
        <br/>
        The CDD measures are taken and performed to the extent necessary considering the Customer’s risk
        profile and other circumstances in the following cases:
        <ul className="list-disc list-inside">
            <li>upon establishment of the Business Relationship and during the ongoing monitoring of the Business Relationship;</li>
            <li>upon executing or mediating of Occasional Transaction(s) outside the Business Relationship;</li>
            <li>upon verification of information gathered while applying due diligence measures or in the case
            of doubts as to the sufficiency or truthfulness of the documents or data gathered earlier while updating
            the relevant data;</li>
            <li>upon suspicion of Money Laundering or Terrorist Financing, regardless of any derogations,
            exceptions or limits provided for in these Guidelines and applicable legislation.</li>
        </ul>
        <br/>
        The Company does not establish or maintain the Business Relationship and does not perform the transaction if:
        <ul className="list-disc list-inside">
            <li>the Company is not able to take and perform any of required CDD measures;</li>
            <li>the Company has any suspicions that the Company’s services or transaction will be used for Money Laundering or Terrorist Financing;</li>
            <li>the risk level of the Customer or of the transaction does not comply with the Company’s risk appetite.</li>
        </ul>
        <br/>
        In the case of receiving information in foreign languages within the framework of CDD
        implementation, the Company may request to demand translation of the documents to another language
        applicable for the Company. The use of translations should be avoided in situations where the original
        documents are prepared in a language appliable for the Company.
        <br/><br/>
        Achieving CDD is a process that starts with the implementation of CDD measures. When that process
        is complete, the Customer is assigned documented individual risk level which shall form the basis for
        follow-up measures, and which is followed up and updated when necessary.
        <br/><br/>
        The Company has applied CDD measures adequately if the Company has the inner conviction that
        they have complied with the obligation to apply due diligence measures. The principle of reasonability
        is observed in the consideration of inner conviction.        
        <br/><br/>
        This means that the Company must, upon the application of CDD measures, acquire the knowledge,
        understanding and assertation that they have collected enough information about the Customer, the
        Customer’s activities, the purpose of the Business Relationship and of the transactions carried out within
        the scope of the Business Relationship, the origin of the funds, etc., so that they understand the Customer
        and the Customer’s (business) activities, thereby taking into account the Customer’s risk level, the risk
        associated with the Business Relationship and the nature of such relationship. Such a level of assertation
        must make it possible to identify complicated, high-value and unusual transactions and transaction
        patterns that have no reasonable or obvious economic or legitimate purpose or are uncharacteristic of the
        specific features of the business in question.
        <br/><br/>
        <b>The Services Provided</b>
        <br/>
        The Company’s main economic activity is the provision of exchange services. For this reason, the
        Company offers to their Customers the following transaction types:
        <ul className="list-disc list-inside">
            <li>providing virtual currency exchange service, which allows the Customer to exchange, virtual
            currency to another one;</li>
            <li>providing exchange service, which allows the Customer to exchange, virtual currency to fiat
            currency; and</li>
            <li>providing exchange service, which allows the Customer to exchange, fiat currency to virtual
            currency.</li>
        </ul>
        <br/>
        The Company also provides a cryptocurrency storage service (Crypto Wallet).
        <br/><br/>
        The Company provides the aforementioned services including but not limited to virtual currencies:
        BTC; USDT (ERC20); USDT (TRC20); TRX; ETH; DOGE; LTC.
        <br/><br/>
        <b>The Verification of Information used for the Customer’s Identification</b>
        <br/>
        Verification of the information for the Customer’s identification means using data from a reliable and
        independent source to confirm that the data is true and correct, also confirming, if necessary, that the data
        directly related to the Customer is true and correct. This, inter alia, means that the purpose of verification
        of information is to obtain reassurance that the Customer, who wants to establish the Business
        Relationship is the person they claim to be.
        <br/><br/>
        The reliable and independent source (must exist cumulatively) is verification of the information obtained in the course of identification:
        <ul className="list-disc list-inside">
            <li>which originates from two different sources;</li>
            <li>which has been issued by (identity documents) or received from a third party or a place that has
                no interest in or connections with the Customer or the Company, i.e. that is neutral (e.g. information
                obtained from the Internet is not such information, as it often originates from the Customer themselves
                or its reliability and independence cannot be verified);
            </li>
            <li>the reliability and independence of which can be determined without objective obstacles and
            reliability and independence are also understandable to a third party not involved in the Business
            Relationship; and</li>
            <li>the data included in which or obtained via which are up to date and relevant and the Company
            can obtain reassurance about this (and reassurance can in certain cases also be obtained on the basis of
            the two previous clauses).</li>
        </ul>
        <br/>
        <b>Application of Standard Due Diligence Measures (level 1)</b>
        <br/>
        Standard due diligence (SDD) measures are applied to all Customers where CDD measures must be
        applied in accordance with the Guidelines.
        <br/><br/>
        SDD measures must not be carried out in the circumstances where enhanced due diligence measures
        (as described below) must be carried out.
        <br/><br/>
        Where, in the course of performing ongoing monitoring of the Customer’s Business Relationships, it
        is established that the risk of ML and/or TF is no longer low, the Company must apply the relevant level
        of CDD measures.
        <br/><br/>
        When applying SDD measures, the Company must obtain the following data of the Customer who is
        a natural person:
        <ul className="list-disc list-inside">
            <li>Name and surname;</li>
            <li>Date of birth;</li>
            <li>Email;</li>
            <li>Country of nationality;</li>
            <li>Taxpayer Identification Number;</li>
            <li>Address of residence;</li>
            <li>Type of identity document;</li>
            <li>Number of identity document, date of issue and expirty;</li>
            <li>Photo of the identity document;</li>
            <li>Selfies with the relevant document;</li>
            <li>Document proving the address of residence of the Customer (utility bill, home telephone bill, internet bill, property rental agreement, fines and court 
                documents, bank statement.
            </li>
        </ul>
        <br/>
        Standard due diligence measures are mandatory for legal person verification entities regardless of 
        the amount of the transaction for which the Company must obtain the following data of the Customer:
        <ul className="list-disc list-inside">
            <li>Name of the director and/or authorised person;</li>
            <li>Legal entity name;</li>
            <li>Email;</li>
            <li>Registration number;</li>
            <li>Date of incorporation;</li>
            <li>Country of incorporation;</li>
            <li>Legal address;</li>
            <li>Email;</li>
            <li>Company website.</li>
        </ul>
        <br/>
        The Customer is also required to upload to the system:
        <ul className="list-disc list-inside">
            <li>Certificate of incorporation (or registry card of the relevant register or document equivalent with 
                an aforementioned documents or relevant documents of establishment of the Customer);
            </li>
            <li>License of the legal entity (if applicable);</li>
            <li>Document proving the identity of the authorised representative (s), director (s), shareholder (s)
                and UBO (s) of the Company;
            </li>
            <li>Selfies with the relevant document;</li>
            <li>Document proving the address of residence of the authorised representative (s), director (s),
                shareholder (s) and UBO (s) of the Company;
            </li>
            <li>Document confirming the relationship (authorisation) of the company's representative with the legal person;</li>
            <li>Document confirming the source of funds of the legal entity;</li>
            <li>Document confirming the actual address of the legal entity.</li>
        </ul>
        <br/>
        The following standard due diligence measures should be applied:
        <ul className="list-disc list-inside">
            <li>identification of the Customer and verification of the submitted information based on information obtained from a reliable and independent source;</li>
            <li>identification and verification of a representative of the Customer and their right of representation;</li>
            <li>identification of the Beneficial Owner and, for the purpose of verifying their identity, taking measures to the extent that allows the Company to make certain 
                that it knows who the Beneficial Owner
                is, and understands the ownership and control structure of the Customer;
            </li>
            <li>understanding of Business Relationship, transaction or operation and, where relevant, gathering information thereon;</li>
            <li>gathering information on whether the Customer is PEP, their family member or a person known to be close associate;</li>
            <li>monitoring of the Business Relationship.</li>
        </ul>
        <br/>
        The CDD measures specified above must be applied before establishing the Business Relationship or
        performing transaction. The exact instruction for application standard due diligence measures is provided
        in the Guidelines.
        <br/><br/>
        <b>Application of Enhanced Due Diligence Measures (level 2)</b>
        <br/><br/>
        In addition to standard due diligence measures, the Company applies enhanced due diligence (EDD)
        measures in order to manage and mitigate an established risk of Money Laundering and Terrorist
        Financing in the case where the risk is established to be higher than usual.
        <br/><br/>
        The Company always applies EDD measures, when:
        <ul className="list-disc list-inside">
            <li>the Customer’s risk profile indicates high risk level of ML/TF;</li>
            <li>a single transaction of natural person or several transactions in an amount equal to or exceeding 
                EUR 15,000 (transactions may be carried out in any currency, the equivalent amount of which is equal 
                to or exceeds EUR 15,000);
            </li>
            <li>upon identification of the Customer or verification of submitted information, there are doubts as
                to the truthfulness of the submitted data, authenticity of the documents or identification of the Beneficial Owner;
            </li>
            <li>the Customer is from such country or territory or their place of residence or seat or the seat of
                the payment service provider of the payee is in a country or territory that, according to credible sources
                such as mutual evaluations, reports or published follow-up reports, has not established effective
                AML/CFT systems that are in accordance with the recommendations of the FATF.
            </li>
        </ul>
        <br/>
        Prior to applying EDD measures, the Company’s Employee ensures that the Business Relationship or
        transaction has a high risk and that a high-risk rate can be attributed to such Business Relationship or
        transaction. Above all, the Employee assesses prior to applying the EDD measures whether the features
        described above are present and applies them as independent grounds (that is, each of the factors
        identified allows application of EDD measures with respect to the Customer).        
        <br/><br/>
        When applying EDD measures where a single transaction of natural person or several transactions in 
        an amount equal to or exceeding EUR 15,000, the Company must apply the following measures:
        <ul className="list-disc list-inside">
            <li>obtaining information on the source of funds and source of wealth of the Customer;</li>
            <li>perform ongoing monitoring of the Business Relationship with the Customer by increasing the
                number and timing of controls applied, and selecting patterns of transactions that need further
                examination;
            </li>
        </ul>
        <br/>
        When applying EDD measures where the Customer is from such country or territory or their place of
        residence or seat or the seat of the payment service provider of the payee is in a country or territory that,
        according to credible sources such as mutual evaluations, reports or published follow-up reports, has not
        established effective AML/CFT systems that are in accordance with the recommendations of the FATF,
        the Company must apply the following measures:
        <ul className="list-disc list-inside">
            <li>obtaining the approval of the Management Board member for establishing Business
                Relationships with the Customer or continuing Business Relationships with them;
            </li>
            <li>obtaining information on the source of funds and source of wealth of the Customer and their Beneficial Owner;</li>
            <li>perform ongoing monitoring of the Business Relationship with the Customer by increasing the
                number and timing of controls applied, and selecting patterns of transactions that need further
                examination.
            </li>
        </ul>
        <br/>
        In any other cases when EDD measures must be applied, the amount of EDD measures and the scope
        shall be determined by the Employee, who is applying such measures. The following additional and
        relevant due diligence measures may be followed:
        <ul className="list-disc list-inside">
            <li>verification of information additionally submitted upon identification of the Customer based on
                additional documents, data or information originating from a credible and independent source;
            </li>
            <li>gathering additional information on the purpose and nature of the Business Relationship or
                transaction and verifying the submitted information based on additional documents, data or information
                that originates from a reliable and independent source;
            </li>
            <li>gathering additional information and documents regarding the actual execution of transactions
                made in the Business Relationship in order to rule out the ostensibility of the transactions;            
            </li>
            <li>gathering additional information and documents for the purpose of identifying the source and
                origin of the funds used in a transaction made in the Business Relationship in order to rule out the
                ostensibility of the transactions;
            </li>
            <li>
                the making of the first payment related to a transaction via an account that has been opened in
                the name of the Customer participating in the transaction in a credit institution registered or having its
                place of business in a contracting state of the European Economic Area or in a country where
                requirements equal to those of Directive (EU) 2015/849 of the European Parliament and of the Council
                are in force;
            </li>
            <li>gathering additional information about the Customer and its Beneficial Owner, including
                identification of all owners of the Customer, incl. those whose shareholding is below 25%;
            </li>
            <li>gathering information on the origin of the funds and wealth of the Customer and its Beneficial Owner;</li>
            <li>improving the monitoring of the Business Relationship by increasing the number and frequency
                of the applied control measures and by choosing transaction indicators or transaction patterns that are
                additionally verified;
            </li>
            <li>obtaining the approval of the Management Board member for performing transactions or establishing business relationship with new and existing Customers.</li>
        </ul>
        <br/>
        In the case of application of EDD measures, the Company reassesses the Customer’s risk profile no
        later than every six months.
        <br/><br/>
        <b>The identification of the Customer’s representative and their right of representation</b>
        <br/><br/>
        The representative of the Customer shall be identified as the Customer, who is a natural person in
        accordance with these Guidelines. The Company must also identify and verify the nature and scope of
        the right of representation of the Customer. The name, date of issue and name of issuer of the document
        that serves as a basis for the right of representation must be ascertained and retained, except in case, when
        the right of representation was verified using information originating from the relevant register.
        <br/><br/>
        The Company must observe the conditions of the right of representation granted to the legal entity’s representatives and provide services only within the scope of 
        the right of representation.
        <br/><br/>
        In case the right of representation of the Customer (legal person) is evident from the registry extract,
        Articles of Association or equivalent documents evidencing the identity of the Customer (legal person),
        a separate document of authorisation (e.g. a Power of Attorney) should not be required.
        <br/><br/>
        <b>Requirements for documents to be submitted by the Сustomer</b>
        <br/><br/>
        All documents provided by the Сustomer must be checked for compliance with the following criteria:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>The document is valid;</li>
            <li>The document is provided in expanded form;</li>
            <li>All characters are readable;</li>
            <li>The corners are not cut;</li>
            <li>Provided all pages of the document (e.g. in case of ID card or residence permit - both sides of thedocument must be uploaded);</li>
            <li>A document confirming the address of residence shall not be older than 3 months.</li>
        </ul>
        <br/>
        By uploading a selfie with an identity document, the Customer must ensure that the photo of the
        uploaded document and the document on the selfie must match; the photo is not blurred, the user's face
        on the photo and on the document is clearly visible and matches; all characters of the identity document
        on the selfie are legible; the document is fully visible on the photo, the edges are not cropped.
        <br/><br/>
        The Company shall regularly <b>check and update the documents, data and information</b> collected 
        within the course of the implementation of CDD measures and update the Customer´s risk profile. The 
        regularity of the checks and update must be based on the risk profile of the Customer and the checks 
        must take place at least:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>once semi-annually for the high-risk profile Customer;</li>
            <li>once annually for the medium-risk profile Customer;</li>
            <li>once every two years for the low-risk profile Customer.</li>
        </ul>
        <br/>
        The collected documents, data and information must also be checked if an event has occurred which  indicates the need to update the collected documents, data and information.
      `
    },
    {
      title: "MONITORING FOR SUSPICIOUS ACTIVITY AND TRANSACTIONS",
      description: `The Company shall monitor the Customer’s Activity to ascertain the activities or facts that indicate
        criminal activities, Money Laundering or Terrorist Financing or the relation of which to Money
        Laundering or Terrorist Financing is probable, incl. complicated, high-value and unusual transactions
        and transaction patterns that do not have any reasonable or obvious economic or legitimate purpose or
        that are uncharacteristic of the specific features of the business in question. In the course of monitoring
        the Customer's activities the Company shall constantly assess the changes in the Customer’s activities
        and assess whether these changes may increase the risk level associated with the Customer, giving rise
        to the need to apply EDD measures.
        <br/><br/>
        In the course of the ongoing monitoring of the Suspicious Transactions, the Company applies the following measures:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>screening i.e., monitoring transactions in real-time;</li>
            <li>monitoring i.e., analyzing transactions later.</li>
        </ul>
        <br/>
        The objective of screening is to identify suspicious and unusual transactions and transaction patterns.
        <br/><br/>
        The screening of the transactions is performed automatically and includes the following measures (including but not limited):
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>the scoring of Virtual Currency wallets where the Virtual Currency shall be sent in accordance with the Customer´s order;</li>
            <li>the scoring of Virtual Currency wallets from which the Virtual Currency is received.</li>
        </ul>
        <br/>
        If the Customer gives request for transaction to the Virtual Currency wallet with high-risk score (e.g.
        wallets related to fraud, crime, etc.), the transaction shall be manually approved by the Employee, who
        shall assess, before the approval, the necessity to apply any additional CDD measures (e. g. applying
        EDD measures, asking source and origin of funds or asking additional information regarding the
        transaction).
        <br/><br/>
        When monitoring transactions the Employee shall assess transaction with a view to detect activities and transactions that:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>deviate from what there is reason to expect based on the CDD measures performed, the services
                provided, the information provided by the Customer and other circumstances (e.g. Virtual Currency
                sending each time to new Virtual Currency wallet, volume of transactions exceeding limit);
            </li>
            <li>without deviating according to previous clause, may be assumed to be part of a Money Laundering
                or Terrorist Financing;
            </li>
            <li>may affect the Customer´s risk profile score.</li>
        </ul>
        <br/>
        In the case, where the aforementioned fact is detected, the AML Officer shall postpone any transaction of the Customer until further review and decision regarding this.
        <br/><br/>
        In addition to aforementioned, the Employee shall review the Company's transactions regularly (at
        least once per week) to ensure that there are no transactions and transaction patterns that are complicated,
        high-value and unusual and that have no reasonable or obvious economic or legitimate purpose or are
        uncharacteristic of the specific features.
        <br/><br/>
        The Company identifies the source and origin of the funds used in transaction(s) if necessary. The
        need to identify the source and origin of funds depends on the Customer’s previous activities as well as
        other known information. Thereby the identification of the source and origin of the funds used in
        transaction shall be performed in the following cases:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>such a check is provided for in this Guideline;</li>
            <li>the transactions do not correspond to the information previously known about the Customer;</li>
            <li>the Company wants to or should reasonably consider it necessary to assess whether the transactions correspond to the information previously known about the Customer;</li>
            <li>the Company suspects that the transactions indicate criminal activities, Money Laundering or
                Terrorist Financing or that the relation of transactions to Money Laundering or Terrorist Financing is
                probable, incl. complicated, high-value and unusual transactions and transaction patterns that do not have
                any reasonable or obvious economic or legitimate purpose or are uncharacteristic of the specific features
                of the business in question.
            </li>
        </ul>
        <br/>
        <b>Red Flags</b>
        <br/><br/>
        The Company shall monitor the Customer’s Activity for unusual size, volume, pattern or type of 
        transactions, taking into account risk factors and red flags that are appropriate to our business. Red flags 
        that signal possible money laundering or terrorist financing include, but are not limited to:
        <br/><br/>
        Potential Red Flags in Customer Due Diligence and Interactions with Customers
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>The Customer provides the firm with unusual or suspicious identification documents that cannot 
                be readily verified or are inconsistent with other statements or documents that the Customer has provided. 
                Or, the Customer provides information that is inconsistent with other available information about the 
                Customer. This indicator may apply to account openings and to interaction subsequent to account 
                opening.
            </li>
            <li>The Customer is reluctant or refuses to provide the firm with complete customer due diligence 
                information as required, which may include information regarding the nature and purpose of the 
                Customer’s business, prior financial relationships, anticipated account activity, business location and, if 
                applicable, the entity’s officers and directors.
            </li>
            <li>The Customer refuses to identify a legitimate source of funds or information is false, misleading or substantially incorrect.</li>
            <li>The Customer is domiciled in, doing business in or regularly transacting with counterparties in a 
                jurisdiction that is known as a bank secrecy haven, tax shelter, high-risk geographic location (e.g., known 
                as a narcotics producing jurisdiction, known to have ineffective AML/Combating the Financing of 
                Terrorism systems) or conflict zone, including those with an established threat of terrorism.
            </li>
            <li>The Customer has difficulty describing the nature of his or her business or lacks general knowledge of his or her industry.</li>
            <li>The Customer has no discernable reason for using the firm’s service or the firm’s location (e.g., 
                the customer lacks roots to the local community or has gone out of his or her way to use the firm).
            </li>
            <li>The Customer has been rejected or has had its relationship terminated as a customer by other 
                financial services firms.
            </li>
            <li>The Customer’s legal or mailing address is associated with multiple other accounts or businesses 
                that do not appear related.
            </li>
            <li>The Customer appears to be acting as an agent for an undisclosed principal, but is reluctant to provide information.</li>
            <li>The Customer’s background is questionable or differs from expectations based on business activities.</li>
            <li>The Customer maintains multiple accounts, or maintains accounts in the names of family 
                members or corporate entities, with no apparent business or other purpose.
            </li>
        </ul>
        <br/>
        <b>Potential Red Flags in Money Movements</b>
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>The Customer “structures” deposits or purchases of monetary instruments below a certain amount 
                to avoid reporting or recordkeeping requirements, and may state directly that they are trying to avoid 
                triggering a reporting obligation or to evade taxing authorities.
            </li>
            <li>The Customer seemingly breaks funds transfers into smaller transfers to avoid raising attention 
                to a larger funds transfer. The smaller funds transfers do not appear to be based on payroll cycles, 
                retirement needs, or other legitimate regular deposit and withdrawal strategies.
            </li>
            <li>The Customer’s account shows numerous currency, money order (particularly sequentially 
                numbered money orders) or cashier’s check transactions aggregating to significant sums without any 
                apparent business or lawful purpose.
            </li>
            <li>The Customer makes a funds deposit followed by an immediate request that the money be wired 
                out or transferred to a third party, or to another firm, without any apparent business purpose.            
            </li>
            <li>Wire transfers are made in small amounts in an apparent effort to avoid triggering identification 
                or reporting requirements.
            </li>
            <li>There is wire transfer activity that is unexplained, repetitive, unusually large, shows unusual 
                patterns or has no apparent business purpose.
            </li>
            <li>The Customer uses a personal/individual account for business purposes or vice versa.</li>
            <li>There are frequent transactions involving round or whole amounts purported to involve payments for goods or services.</li>
            <li>Upon request, a Customer is unable or unwilling to produce appropriate documentation to support a transaction, or documentation appears doctored or fake.</li>
            <li>A dormant account suddenly becomes active without a plausible explanation (e.g., large deposits 
                that are suddenly wired out).
            </li>
            <li>Wire transfer activity, when viewed over a period of time, reveals suspicious or unusual patterns, 
                which could include repetitive transactions or circuitous money movements.
            </li>
        </ul>
        <br/>
        <b>Other Potential Red Flags</b>
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>The Customer is reluctant to provide information needed to file reports to proceed with the transaction.</li>
            <li>The Customer exhibits unusual concern with the firm’s compliance with government reporting requirements and the firm’s AML policies.</li>
            <li>The Customer tries to persuade an employee not to file required reports or not to maintain the required records.</li>
            <li>Law enforcement has issued subpoenas or freeze letters regarding a Customer or account at the securities firm.</li>
            <li>The Customer makes high-value transactions not commensurate with the Customer’s known income or financial resources.</li>
            <li>The stated business, occupation or financial resources of the Customer are not commensurate with the type or level of activity of the Customer.</li>
            <li>The Customer does not exhibit a concern with the cost of the transaction or fees (e.g., surrender fees, or higher than necessary commissions).</li>
        </ul>
        <br/>
        Upon detection of any red flag, or other activity that may be suspicious, AML Officer shall determine 
        whether or not and how to further investigate the matter. This may include gathering additional 
        information internally or from third-party sources, contacting the government, freezing the account 
        and/or filing an appropriate report to SANS.
      `
    },
    {
      title: "IMPLEMENTATION OF SANCTIONS",
      description: `Upon the entry into force, amendment or termination of Sanctions, the Company shall verify whether
        the Customer, their Beneficial Owner or a person who is planning to have the Business Relationship or
        transaction with them is a subject of Sanctions. If the Company identifies a person who is a subject of
        Sanctions or that the transaction intended or carried out by them is in breach of Sanctions, the Company
        shall apply Sanctions and inform the SANS.
        <br/><br/>
        <b>Procedure for identifying the subject of Sanctions and a transaction violating Sanctions</b>
        <br/><br/>
        The Company shall use at least the following sources (databases) to verify the Customer´s relation to Sanctions:        
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>A consolidated list of EU sanctions;</li>
            <li>A consolidated list of United Nations sanctions;</li>
            <li>A consolidated version of the sanctions available on the website of the Ministry of Foreign Affairs.</li>
        </ul>
        <br/>
        In addition to aforementioned sources, the Company may use any other sources by the decision of the Employee who is applying CDD measures.
        <br/><br/>
        To verify that the persons’ names resulting from the inquiry are the same as the persons listed in a
        notification containing Sanction(s), their personal data shall be used, the main characteristics of which
        are, for a legal entity, its name or trademark, registry code or registration date, and for a natural person,
        their name and personal identification or date of birth.
        <br/><br/>
        In order to establish the identity of the persons specified in the relevant legal act or notice being the
        same as those identified as a result of the inquiry from databases, the Company must analyze the names
        of the persons found as a result of the inquiry based on the possible effect of factors distorting personal
        data (e. g. transcribing foreign names, different order of words, substitution of diacritics or double letters
        etc.).
        <br/><br/>
        The Company shall perform abovementioned verification on an ongoing basis in the course of an
        established Business Relationship. The frequency of the ongoing verifications depends on the Customer’s
        risk profile:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>once per week for the high-risk profile Customer;</li>
            <li>once per month for the medium-risk profile Customer;</li>
            <li>once per quarter for the low-risk profile Customer.</li>
        </ul>
        <br/>
        If the Employee has doubts that a person is a subject of Sanctions, the Employee shall immediately
        notify the Management Board member. In this case the Management Board member shall decide whether
        to ask or acquire additional data from the person or notify the SANS immediately of their suspicion.
        <br/><br/>
        The Company shall primarily acquire additional information on their own about the person who is in
        Business Relationship or is performing a transaction with them, as well as the person intending to
        establish the Business Relationship, perform a transaction or an act with them, preferring information
        from a credible and independent source. If, for some reason, such information is not available, the
        Company shall ask the person who is in the Business Relationship or is performing a transaction or an
        act with them, as well as the person intending to establish a Business Relationship, perform a transaction
        or an act with them, whether the information is from a credible and independent source and assess the answer.
        <br/><br/>
        <b>Actions when identifying the Sanctions subject or a transaction violating Sanctions</b>
        <br/><br/>
        If the Employee of the Company becomes aware that the Customer which is in Business Relationship
        or is performing a transaction with the Company, as well as a person intending to establish the Business
        Relationship or to perform a transaction with the Company, is the subject of Sanctions, the Employee
        shall immediately notify the Management Board member, about the identification of the subject of
        Sanctions, of the doubt thereof and of the measures taken.
        <br/><br/>
        The Management Board member shall refuse to conclude a transaction or proceeding, shall take
        measures provided for in the act on the imposition or implementation of the Sanctions and shall notify
        immediately the SANS of their doubts and of the measures taken.
        <br/><br/>
        When identifying the subject of the Sanctions, it is necessary to identify the measures that are taken
        to Sanction this person. These measures are described in the legal act implementing the Sanctions,
        therefore it is necessary to identify the exact sanction what is implemented against the person to ensure
        legal and proper application of measures.
      `
    },
    {
      title: "REFUSAL TO THE TRANSACTION OR BUSINESS RELATIONSHIP AND THEIR TERMINATION",
      description: `The Company is prohibited to establish a Business Relationship and the established Business Relationship or transaction shall be terminated 
        (unless it is objectively impossible to do) in case when:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>the Company suspects Money Laundering or Terrorist Financing;</li>
            <li>it is impossible for the Company to apply the CDD measures, because the Customer does not
                submit the relevant data or refuses to submit them or the submitted data gives no grounds for reassurance
                that the collected data are adequate;
            </li>
            <li>the Customer who is a natural person behind whom is another, actually benefiting person, wants
                to establish the Business Relationship (suspicion that a person acting as a front is used);
            </li>
            <li>the Customer´s risk profile has become inappropriate with the Company´s risk appetite (i. e. the
                Customer´s risk profile level is “prohibited”)
            </li>
            <li>the Customer a natural person or an authorised person (s), UBO (s), director (s) and/orshareholder
                (s) (if the Customer is a legal entity) is/are a resident of Prohibited countries (list below) or other States
                where these Company's services cannot be provided in accordance with national legal requirements
            </li>
            <li>the Customer - legal entities, conducts business activities that are unacceptable to the Company (list below)</li>
        </ul>
        <br/>
        <b>Prohibited business activities</b>
        <br/><br/>
        <ol className="list-decimal list-inside">
            <li>Gambling (including casino chip payment, gambling services, internet casino sites, Bingo) and betting services (betting).</li>
            <li>Lotteries.</li>
            <li>Prostitution, sale of Internet content of erotic and pornographic nature, intimate goods and services.</li>
            <li>Sale of counterfeit and/or falsified goods/services.</li>
            <li>Sale of precious and rare earth metals and products thereof.</li>
            <li>Sale of weapons, ammunition, military equipment, spare parts, component parts and devices for 
                them, explosives, blasting agents, gunpowder, all types of rocket fuel, as well as special materials and 
                special equipment for their production, special equipment for personnel of paramilitary organisations 
                and normative and technical products for their production and operation.
            </li>
            <li>The sale of rocket-space systems, military communication and control systems and normative-technical documentation for their production and operation.</li>
            <li>Sale of chemical warfare agents, means of protection against them and normative-technical documentation for their production and use.</li>
            <li>Sale of uranium, other fissile materials and products from them.</li>
            <li>Sale of x-ray equipment, instruments and equipment using radioactive substances and isotopes.</li>
            <li>Sale of the results of research and development and design work, as well as fundamental exploratory research for the creation of weapons and military equipment.</li>
            <li>Sale of poisons, narcotics and psychotropic substances.</li>
            <li>Sale of waste radioactive materials.</li>
            <li>Sale of waste explosives.</li>
            <li>Sale of waste containing precious and rare-earth metals and gemstones.</li>
            <li>Sale of special and other technical means intended (developed, adapted, programmed) for 
                surreptitious obtaining of information, normative and technical documentation for their production and use.
            </li>
            <li>Sale of radio electronic means (high-frequency devices, consisting of one or more radio 
                transmitters and (or) their combinations and auxiliary equipment, designed for transmission and 
                reception of radio waves at frequencies above 9 kHz): radio stations, radio telephones, radio navigation 
                systems, radio identification, radio location, radio surveillance, radio interception, radio suppression, 
                radio direction finding and cable television; auxiliary equipment designed for transmission and 
                reception of radio waves.
            </li>
            <li>Sale of databases containing personal data.</li>
            <li>Sale of other goods/services, the free sale of which: is prohibited or restricted under applicable
                law; could have a negative effect on business reputation.
            </li>
        </ol>
        <br/>
        <b>Prohibited countries</b>
        <br/><br/>
        The unsupported countries or territories are Afghanistan, Albania, Angola, The Bahamas, Barbados,
        Belarus, Botswana, Burundi, Cambodia, Central African Republic, the Democratic Republic of Congo,
        Cote D'Ivoire, Crimea (Ukrainian Territory, Occupied), Cuba, Democratic People's Republic of Korea
        (DPRK), Donetsk region (Ukrainian Territory, Occupied), Eritrea, Gaza Strip, Ghana, Guinea Bissau,
        Jamaica, The Islamic Republic of Iran, Iraq, Lebanon, Liberia, Libya, Luhansk region (Ukrainian
        Territory, Occupied), Mali, Mauritius, Mongolia, Myanmar, Nicaragua, North Korea, Pakistan, Panama,
        Russian Federation, Sierra Leone, Somalia, South Sudan, Sudan, Syria, Trinidad and Tobago, Uganda,
        Vanuatu, Venezuela, West Bank (Palestinian Territory, Occupied), Yemen and Zimbabwe.
        <br/><br/>
        <b>Prohibited Transactions</b>
        <br/><br/>
        The Transaction may be carried out only with the consent of the MB if:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>The Customer does not have sufficient authorizations to carry out the Transaction, or the authorizations are unclear,</li>
            <li>The Customer’s need to carry out the Transaction has not been reasonably justified,</li>
            <li>The management, ownership and control structure of the Customer being a legal person is unclear
                and/or it is structured in an unreasonably complicated way from the economic point of view, or it has
                changed frequently without justification,
            </li>
            <li>Economic activities of a legal person or its accounting or payment practices are not transparent,
                the Customer may be a fictitious company or a fictitious person,
            </li>
            <li>The Beneficial Owner of the Customer being legal person cannot be established,</li>
            <li>The Customer being a legal person uses an agent or another legal person as its representative
                without clear authorizations (i.e. during pre-contract negotiations),
            </li>
            <li>The Customer or the representative of the Customer refuses to provide information for the
                purposes of establishing the substance of the Transactions and assessment of the risks
            </li>
            <li>The Customer has not presented sufficient data or documents to prove legal origin of the assets
                and funds, after having been asked to do so,
            </li>
            <li>The Customer, the Beneficial Owner of a Customer being a legal person, or another person
                associated with the Customer is or has been linked with organized crime, ML or TF,
            </li>
            <li>The Customer, the Beneficial Owner of a Customer being a legal person, or another person
                associated with the Customer is or has been linked with traditional sources of income of organized crime,
            </li>
            <li>International Sanctions are being applied against the Customer, the Beneficial Owner of a
                Customer being a legal person, or another person associated with the Customer,
            </li>
            <li>The Customer has nominee shareholders or shares in bearer form</li>
        </ul>
      `
    },
    {
      title: "REPORTING OBLIGATION",
      description: `The Company must suspend the transaction disregarding the amount of the transaction (except for the
        cases where this is objectively impossible due to the nature of the Monetary Operation or transaction, the
        manner of execution thereof or other circumstances) and through its MLRO must report to the SANS on
        the activity or the circumstances that they identify in the course of economic activities and whereby:
        <br/><br/>
        <ul className="list-disc list-inside">
            <li>the Company has established that the Customer is carrying out a suspicious transaction;</li>
            <li>the Company knows or suspects that assets of any value are obtained directly or indirectly from
                criminal activity or participation in such activity;
            </li>
            <li>the Company knows or suspects that assets are used to finance terrorism;</li>
            <li>in other cases provided for by Bulgarian law and other applicable regulations.</li>
        </ul>
        <br/>
        The reports specified above must be made before the completion of the transaction if the Company
        suspects or knows that Money Laundering or Terrorist Financing or related crimes are being committed
        and if said circumstances are identified before the completion of the transaction.
        <br/><br/>
        If the necessity of abovementioned report arises, the Employee to whom such necessity became known
        must immediately notify the Management Board and the MLRO about this.
        <br/><br/>
        All the reports described in this chapter shall be sent in accordance with the Company’s reporting
        guidelines through a secure channel ensuring full confidentiality.
        <br/><br/>
        The Company, a structural unit of the Company, a Management Board member, MLRO and the
        Employee is prohibited to inform a person, its Beneficial Owner, representative or third party about a
        report submitted on them to the SANS, a plan to submit such a report or the occurrence of reporting as
        well as about a precept made by the SANS or about the commencement of criminal proceedings.
      `
    },
    {
        title: "TRAINING OBLIGATION",
        description: `The Company ensures that its MLRO and Employee (s) have the relevant qualifications for their work 
        tasks. When an Employee is recruited or engaged, his/her qualifications are checked as part of the 
        recruitment/appointment process by carrying out background checks.
        <br/><br/>
        In accordance with the requirements applicable to the Company on ensuring the suitability of 
        Employees, the Company makes sure that such persons receive appropriate training and information on 
        an ongoing basis to be able to fulfil the Company’s obligations in compliance with the applicable 
        legislation. It is ensured through training that such persons are knowledgeable within the area of 
        AML/CFT to an appropriate extent considering the person’s tasks and function. For new Employees, the 
        training comprises a review of the content of the applicable rules and regulations, the Company’s risk 
        assessment policy, these Guidelines and other relevant procedures.
        <br/><br/>
        The training for MRLO and Employee (s) must provide, first and foremost, information on all the 
        most contemporary money laundering and terrorist financing methods and risks arising therefrom. It 
        refers to relevant parts of the content of the applicable rules and regulations, the Company’s risk
        assessment, the Company’s Guidelines and procedures and information that should facilitate such MRLO 
        and Employee (s) detecting suspected Money Laundering and Terrorist Financing. The training is 
        structured on the basis of the risks identified through the risk assessment policy.        
        <br/><br/>
        This training programme should therefore include, at a minimum: 
        <br/><br/>
        <ol className="list-decimal list-inside">
            <li> how to identify red flags and signs of money laundering that arise during the course of the employees’ duties;</li>
            <li>what to do once the risk is identified (including how, when and to whom to escalate unusual 
                customer activity or other red flags for analysis and, where appropriate, the filing of SANS);
            </li>
            <li>what employees' roles are in the firm's compliance efforts and how to perform them;</li>
            <li>the firm's record retention policy; and</li>
            <li>the disciplinary consequences (including civil and criminal penalties) for non-compliance with applicable law</li>
        </ol>
        <br/>
        Training will occur on at least an annual basis. The content and frequency of the training is adapted 
        to the person’s tasks and function on issues relating to AML/CFT measures. If the Guidelines is updated 
        or amended in some way, the content and frequency of the training is adjusted appropriately.
        <br/><br/>
        The Company develop training in our firm, or contract for it. Delivery of the training may include 
        educational pamphlets, videos, intranet systems, in-person lectures and explanatory memos.
        <br/><br/>
        The training held is to be documented electronically and confirmed with the signature. This 
        documentation should include the content of the training, names of participants and date of the training.
        `
    },
    {
        title: "COLLECTION AND STORING OF DATA, LOGBOOKS",
        description: `The Company automatically or through the person (incl. Employees, Management Board members
            and MLRO) who firstly receives the relevant information or documents shall register and retain the
            following data:            
            <br/><br/>
            <ul className="list-disc list-inside">
                <li>the Company has established that the Customer is carrying out a suspicious transaction;</li>
                <li>all data collected within CDD measures implementation;</li>
                <li>information about the circumstances of refusal of the establishment of the Business Relationship by the Company;</li>
                <li>the circumstances of the refusal to establish Business Relationship on the initiative of the Customer if the refusal is related to the application of 
                    CDD measures by the Company;
                </li>
                <li>information on all of the operations made to identify the person participating in the transaction
                    or the Customer´s Beneficial Owner;
                </li>
                <li>information if it is impossible to perform the CDD measures;</li>
                <li>information on the circumstances of termination of the Business Relationship in connection
                    with the impossibility of application of the CDD measures;
                </li>
                <li>the each transaction date or period and a description of the contents of the transaction, including
                    the transaction amount, the currency and the account number or another identifier (incl. hash of
                    transactions in Virtual Currency and Virtual Currency wallets related to transaction);
                </li>
                <li>information serving as the basis for the reporting obligations specified in the Guidelines;</li>
                <li>data of suspicious or unusual transactions or circumstances of which the SANS was not notified
                    (e. g. complex or unusually large transactions, transactions conducted in an unusual pattern and
                    transactions that do not have an apparent economic or lawful purpose, Business Relationships or
                    Monetary Operations with customers from Third Countries where measures to prevent Money
                    Laundering and/or Terrorist Financing are insufficient or do not meet international standards according
                    to information officially published by international intergovernmental organizations).
                </li>
            </ul>
            <br/>
            The data specified above shall be retained for 5 years.
            <br/><br/>
            Documents and data must be retained in a manner that allows for exhaustive and immediate response
            to the queries made by the SANS or, pursuant to legislation, other supervisory authorities, investigation
            authorities or the court.
            <br/><br/>
            The Company implements all rules of protection of personal data upon application of the requirements
            arising from the applicable law. The Company is allowed to process personal data gathered upon CDD
            implementation only for the purpose of preventing Money Laundering and Terrorist Financing and the
            data must not be additionally processed in a manner that does not meet the purpose, for instance, for
            marketing purposes.
            <br/><br/>
            The Company deletes the retained data after the expiry of the time period, unless the legislation
            regulating the relevant field establishes a different procedure. On the basis of a precept of the competent
            supervisory authority, data of importance for prevention, detection or investigation of Money Laundering
            or Terrorist Financing may be retained for a longer period, but not for more than two years after the
            expiry of the first time period.
            <br/><br/>
            The storage of log data shall be completed and kept on an electronic medium by the Management
            Board member, if he/she is on a business trip, or is otherwise unavailable for other valid reasons, another
            Employee, as indicated in the special order of the director, setting out the scope of duties and
            responsibilities assigned to an individual acting as a substitute.
            <br/><br/>
            The Employees of the Company shall be prohibited to inform, or otherwise let know, any Customer
            or other individuals that information on the Monetary Operations taking place, or transactions conducted
            by a Customer, or resulting investigation is communicated to the SANS.
        `
    },
    {
        title: "INTERNAL CONTROL OF EXECUTION OF THE GUIDELINES",
        description: `The performance of the Guidelines shall be internally controlled by the Management Board member,
            the MRLO or the Employee appointed by the MRLO or the Management Board for performing relevant
            functions (hereinafter in this chapter – Internal Control Officer). The Internal Control Officer must have
            the required competency, tools, and access to the relevant information in all structural units of the
            Company
            <br/><br/>
            The Internal Control Officer shall perform internal control functions at least in the following fields:
            <br/><br/>
            <ul className="list-disc list-inside">
                <li>the Company´s compliance with established risk assessment policy and risk appetite;</li>
                <li>CDD measures implementation;</li>
                <li>implementation of Sanctions;</li>
                <li>the Company´s obligation to refusal to the transaction or business relationship and their termination;</li>
                <li>the Company´s reporting obligation to the SANS;</li>
                <li>the Company´s training obligation regarding the AML/CFT requirements;</li>
                <li>the Company´s obligation for collection and preservation of data.</li>
            </ul>
            <br/>
            The exact measures for performing internal control shall be determined by the Internal Control Officer
            and must correspond to the Company’s size and their nature, scope and level of complexity of the
            activities and services provided. The Internal Control Offices must consider at least examination fields
            specified above. The internal control measures shall be performed at the time determined by the Internal
            Control Officer with the frequency set by him or her, at least once per year, if the nature of measure does
            not expressly provide otherwise.
            <br/><br/>
            The results of internal control measures implementation (hereinafter in this chapter – the Internal
            Control Data) shall be saved separately from other data and retained within 3 years. Only Management
            Board members and Internal Control Officer may have access to the Internal Control Data. Internal
            Control Officer may provide access to the Internal Control Data to other Employees or third parties (e.
            g. advisors, auditors, etc.) only with prior consent of Management Board. The persons have access to the
            Internal Control Data must not disclose it to anyone without prior consent of the Management Board.
            <br/><br/>
            The Management Board and the MRLO shall review the internal control report provided and make
            resolution regarding it. The Internal Control Officer shall be notified about the essence of such resolution
            in format which can be reproduced in writing. For this reason, the Management Board and the MRLO is
            obliged to:
            <ul className="list-disc list-inside">
                <li>analyze the results of performed internal control;</li>
                <li>implement actions to eliminate deficiencies occurred.</li>
            </ul>
            <br/>
            The Company must review and, if necessary, update internal control procedure at least annually and in the following cases:
            <ul className="list-disc list-inside">
                <li>following the publication by the European Commission of the results of an EU-wide money
                laundering and terrorist financing risk assessment (available on the European Commission’s
                website <a className="underline" href="http://ec.europa.eu">http://ec.europa.eu</a>);</li>
                <li>after the publication of the results of the National Money Laundering and Terrorist Financing Risk Assessment;</li>
                <li>in the event of significant events or changes in the management and operations of the depository
                    virtual currency money operator and the virtual currency exchange operator.
                </li>
            </ul>
        `
    },
  ]