import React from "react";

const CoinCard = ({ coin, icon, abr, sum, status, positive }) => {
  return (
    <div
      className="w-[184px] h-[180px] p-3 flex items-center justify-between flex-col rounded-[10px]"
      style={{ background: "rgba(255, 255, 255, 0.08)" }}
    >
      <img src={`${icon}`} alt="" className="h-[46px] w-[46px]" />
      <p className="text-white">{coin}</p>
      <p className="text-[#575B66] mb-2 text-xs max-sm:mb-0">{abr}</p>
      <p className="text-white">${sum}</p>
      <div
        className="flex items-center gap-[1px]"
        style={
          positive > 0
            ? { color: "var(--leaf-06, #40BF6A)" }
            : { color: "var(--infra-red-06, #DF2040)" }
        }
      >
        <img
          src={`${
            positive > 0
              ? "/icons/arrow-up.png"
              : "/icons/arrow-bottom.png"
          }`}
          alt="Arrow Up"
          className="h-4 w-4"
        />
        <p className="text-xs">{status}%</p>
      </div>
    </div>
  );
};

export default CoinCard;
