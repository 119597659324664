import React from 'react'
import { terms } from '../../lib/data/termsAndConditions'
import parse from "html-react-parser";

const Content = () => {
  return (
    <div className='container py-[150px]'>
      <div className='flex flex-col gap-[42px]'>
      <p className="md:text-[54px] text-4xl font-[600] text-white">Terms & Conditions</p>
      <div className="h-px bg-gray-500"></div>
        {terms.map((term)=>(
          <div>
            <p className='text-white text-lg font-[600] mb-3'>{term.title}</p>
            <p className='text-text-desc'>{parse(term.description)}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Content