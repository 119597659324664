import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MobileNavbar from "./MobileNavbar";
import Logo from "../Logo";
// import { useContext } from "react";
// import { OpeningSoonContext } from "../../context/openingSoonContext";
// import LanguageSelector from "./LanguageSelector";

const Navbar = () => {
  // const { setWarning } = useContext(OpeningSoonContext);
  const [scrolled, setScrolled] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
  const [openMobile, setOpenMobile] = useState(false);
  
  const updateDimensions = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  // function getAnimationOpen() {
  //   if (dimensions.width > 1476) {
  //     return "width-transition";
  //   } else if (dimensions.width > 1200) {
  //     return "width-transition2";
  //   } else if (dimensions.width > 944) {
  //     return "width-transition3";
  //   }
  // }

  // function getAnimationClose() {
  //   if (dimensions.width > 1476) {
  //     return "transition-width-nav";
  //   } else if (dimensions.width > 1200) {
  //     return "transition-width-nav2";
  //   } else if (dimensions.width > 944) {
  //     return "transition-width-nav3";
  //   }
  // }

  return (
    <>
      <div
        className={`w-full lg:m-auto`}
      >
        <div
          className={`bg-[#353739] p-5 flex items-center shadow-sm min-lg:w-full justify-between max-sm:px-2 max-sm:py-3`}
        >
          <div className="w-5/12 lg:w-4/12 xl:w-5/12 max-sm:w-8/12">
            <div className="lg:hidden">
              <Logo height='true'/>
            </div>
            <div className="max-lg:hidden lg:flex items-center gap-5">
              <Link to="https://app.easycrypto365.com" target="_blank">
                <p className="text-white text-base font-medium">
                  Buy Crypto
                </p>
              </Link>
              <Link to="/">
                <p className="text-white text-base font-medium">
                  Home
                </p>
              </Link>
              <Link to="/trade">
                <p className="text-white text-base font-medium">
                  Trade
                </p>
              </Link>
              <Link to="/about">
                <p className="text-white text-base font-medium">
                  About
                </p>
              </Link>
            </div>
          </div>
          <div className="flex max-sm:w-4/12 w-7/12 2xl:w-8/12 justify-between max-lg:justify-end">
            <div className="max-lg:hidden">
              <Logo height='true'/>
            </div>
            <div className="flex items-center gap-2">
              <Link to="https://app.easycrypto365.com/login">
                <div className="
                  text-white text-sm px-3 py-2 font-semibold max-lg:hidden max-sm:text-xs border
                  rounded-lg hover:bg-white/10 w-20 h-10 flex justify-center items-center"
                >
                  Sign In
                </div>
              </Link>
              <Link to="https://app.easycrypto365.com/register">
                <div className="
                 text-white text-sm max-sm:text-xs bg-custom-primary hover:bg-custom-primary-dark transition-colors
                  px-3 py-2 rounded-lg font-semibold max-sm:hidden w-20 h-10 flex justify-center items-center"
                >
                  Sign Up
                </div>
              </Link>
              <div className="items-center max-lg:flex lg:hidden ml-2">
                <FontAwesomeIcon
                  onClick={() => setOpenMobile(true)}
                  icon={faBars}
                  className="text-[30px] md:text-[40px] text-white max-sm:text-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileNavbar open={openMobile} setOpen={setOpenMobile} />
    </>
  );
};

export default Navbar;