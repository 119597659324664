import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OurMission = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto my-44">
      <p className="text-3xl md:text-5xl text-white font-semibold text-center mb-8">
        {t("about.mission.title")}
      </p>
      <div className="flex max-md:flex-col-reverse justify-between items-center">
        <div className="w-full md:w-[48%] lg:w-[30%]">
          <p className="text-4xl text-custom-primary font-semibold mb-2 max-md:hidden">
            {t("about.mission.stats.title")}
          </p>
          <p className="text-white/70 max-md:hidden">
            {t("about.mission.stats.description")}
          </p>
          <div className="cursor-pointer text-white bg-custom-primary hover:bg-custom-primary-dark w-fit px-6 py-3 font-semibold rounded-lg mt-8 transition-colors max-md:w-full max-md:text-center">
            <Link to="https://app.easycrypto365.com/login">
              {t("about.mission.stats.start")}
            </Link>
          </div>
        </div>
        <img
          src="rocket-lying.png"
          loading="lazy"
          alt="Rocket Flying"
          className="w-[30%] h-fit max-lg:hidden"
        />

        <div className="w-full md:w-[48%] lg:w-[30%] flex flex-col justify-start gap-14 lg:gap-10">
          <div className="flex items-start gap-5">
            <img src="/icons/easy-to-use.svg" alt="" className="w-9" />
            <div>
              <p className="text-xl text-white">{t("about.mission.easy.title")}</p>
              <p className="text-white/70 text-sm">
                {t("about.mission.easy.description")}
              </p>
            </div>
          </div>
          <div className="flex items-start gap-5">
            <img src="/icons/worldwide.svg" alt="" className="w-9" />
            <div>
              <p className="text-xl text-white">{t("about.mission.expand.title")}</p>
              <p className="text-white/70 text-sm">
              {t("about.mission.expand.description")}
              </p>
            </div>
          </div>
          <div className="flex items-start gap-5">
            <img src="/icons/new-feature.svg" alt="" className="w-9" />
            <div>
              <p className="text-xl text-white">{t("about.mission.features.title")}</p>
              <p className="text-white/70 text-sm">
              {t("about.mission.features.description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
