import React from "react";
import { Parallax } from "react-scroll-parallax";

// COMPONENTS
import Hero from "../components/about/Hero";
import Counter from "../components/about/Counter";
import ImageText from "../components/about/ImageText";
import OurMission from "../components/about/OurMission";
// import Reviews from "../components/about/Reviews";
import CallToAction from "../components/home/CallToAction";

const About = () => {
  return (
    <div>
      <Hero />
      <Parallax speed={20}>
        <Counter />
      </Parallax>
      <Parallax speed={20}>
        <ImageText
          content="about.about.description"
          title="about.about.title"
          image="/about-us.jpg"
        />
        <ImageText
          content="about.team.description"
          title="about.team.title"
          image="/our-team.jpg"
          side="right"
        />
        <ImageText
          content="about.support.description"
          title="about.support.title"
          image="/our-support.jpg"
        />
      </Parallax>
      <Parallax speed={20}>
        <OurMission />
      </Parallax>
      {/* <Parallax speed={20}>
        <Reviews />
      </Parallax> */}
      <CallToAction />
    </div>
  );
};

export default About;
