import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


const SelectModal = ({
  selectedModal,
  setSelectModal,
  setSellSelected,
  setGetSelected,
  sellSelected,
  getSelected,
  coinsData
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const modalRef = useRef(null);
  const {t} = useTranslation();

  function setSelected(value) {
    setSelectedValue(value.symbol);
    if (selectedModal === "sell") {
      setSellSelected(value.symbol);
      setSelectModal(false);
    } else if (selectedModal === "get") {
      setGetSelected(value.name);
      setSelectModal(false);
    }
  }

  const currencies = [
  {
    name: "EUR",
    label: "Euro",
    image: "/icons/EUR.svg"
  },
  {
    name: "USD",
    label: "United States Dollar",
    image: "/icons/USD.svg"
  },
  {
    name: "GBP",
    label: "Great British Pound",
    image: "/icons/GBP.svg"
  },
];

  useEffect(() => {
    if (selectedModal === "sell") {
      setSelectedValue(sellSelected);
    } else if (selectedModal === "get") {
      setSelectedValue(getSelected);
    }
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectModal(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);
  return (
    <div className="fixed bg-black/40 top-0 left-0 w-[100vw] h-[100vh] flex justify-center items-center">
      <div
        className="bg-[#3A3A3B] border-[#ffffff]/20 border-[0.5px]   rounded-2xl fadeInTrade max-sm:w-[90%] mt-32 sm:mt-48"
        ref={modalRef}
      >
        <div className="w-full px-4 pt-4">
          <div className="flex justify-between">
            <p className="font-medium text-lg text-white mb-2">{t("trade.select.title")}</p>
            <FontAwesomeIcon
              icon={faXmark}
              className="text-xl text-white/50 cursor-pointer"
              onClick={() => setSelectModal(false)}
            />
          </div>
          <div className="py-[12px] rounded-lg bg-white/20 text-white outline-none border-white/20 border-[1px] gap-3 flex items-center w-full sm:w-[516px] px-[14px]">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-lg" />
            <input type="text" className="flex-1 bg-transparent outline-none" />
          </div>
        </div>

        {selectedModal === "sell" ? (
        <div className="max-h-[342px] overflow-scroll m-1 p-3">
          {coinsData.slice(0, 5).map((coin) => (
            <div
              onClick={() => setSelected(coin)}
              className="flex p-[10px] hover:bg-custom-primary/10 rounded-md border-[1px] border-transparent hover:border-custom-primary/50 cursor-pointer items-center justify-between text-white hover:text-custom-primary"
            >
              <div className="flex gap-3 items-center">
                <img
                  src={`${coin?.image}`}
                  alt=""
                  className="w-8 h-8"
                />
                <p>{coin?.name}</p>
                <p className="uppercase">{coin?.symbol}</p>
              </div>
              <div
                className={`p-2 rounded-full  border-[0.5px] ${
                  selectedValue === coin.symbol
                    ? "bg-custom-primary/60 border-custom-primary/90"
                    : "bg-custom-primary/10 border-custom-primary/70"
                }`}
              ></div>
            </div>
          ))}
        </div>
        ) : (
        <div className="max-h-[342px] overflow-scroll m-1 p-3">
          {currencies.map((currency) => (
            <div
              onClick={() => setSelected(currency)}
              className="flex p-[10px] hover:bg-custom-primary/10 rounded-md border-[1px] border-transparent hover:border-custom-primary/50 cursor-pointer items-center justify-between text-white hover:text-custom-primary"
            >
              <div className="flex gap-3 items-center">
                <img
                  src={`${currency?.image}`}
                  alt=""
                  className="w-8 h-8"
                />
                <p>{currency?.label}</p>
                <p className="uppercase">{currency?.name}</p>
              </div>
              <div
                className={`p-2 rounded-full  border-[0.5px] ${
                  selectedValue === currency.name
                    ? "bg-custom-primary/60 border-custom-primary/90"
                    : "bg-custom-primary/10 border-custom-primary/70"
                }`}
              ></div>
            </div>
          ))}
        </div>
        )};

      </div>
    </div>
  );
};

export default SelectModal;
