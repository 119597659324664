import React from "react";
import { useDispatch } from "react-redux";
import { changeMessages } from "../../store/slice";
import { useTranslation } from "react-i18next";

const AskForOurSupport = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  function submitEmail() {
    dispatch(
      changeMessages({
        message: "Email sent successfully",
        title: "Success",
        status: "success",
      })
    );
  }

  return (
    <div className="my-[300px] w-[80%] lg:w-[55%] mx-auto">
      <p className="text-white text-center font-bold text-[30px] md:text-[42px]">
        {t("askForSupport.title")}
      </p>
      <p className="text-white w-[90%] md:w-[65%] text-center mb-8 mx-auto text-white/70 max-md:text-xs max-lg:text-sm">
        {t("askForSupport.description")}
      </p>
      <div className="sm:flex justify-between">
        <div className="sm:w-[49%]">
          <p className="font-semibold text-white text-base mb-3">
            {t("askForSupport.form.fullName")}
          </p>
          <input
            type="text"
            placeholder="Name Surname"
            className="bg-custom-secondary/50 border border-white/20 outline-none rounded-md py-3 px-4 w-full text-white/80"
          />
        </div>
        <div className="sm:w-[49%] max-sm:mt-3">
          <p className="font-semibold text-white text-base mb-3">
            {t("askForSupport.form.email")}
          </p>
          <input
            type="email"
            placeholder="email@gmail.com"
            className="bg-custom-secondary/50 border border-white/20 outline-none rounded-md py-3 px-4 w-full text-white/80"
          />
        </div>
      </div>
      <div className="mt-3">
        <p className="font-semibold text-white text-base mb-3">
          {t("askForSupport.form.subject")}
        </p>
        <input
          type="email"
          placeholder="Email"
          className="bg-custom-secondary/50 border border-white/20 outline-none rounded-md py-3 px-4 w-full text-white/80"
        />
      </div>
      <div className="mt-3">
        <p className="font-semibold text-white text-base mb-3">
          {t("askForSupport.form.explain")}
        </p>
        <textarea
          className="h-[200px] w-full resize-none bg-custom-secondary/50 border border-white/20 outline-none rounded-md py-3 px-4 text-white"
          placeholder="Your Explanation..."
        ></textarea>
      </div>
      <div
        className="bg-custom-primary hover:bg-custom-primary-dark text-black font-semibold text-center py-2 px-8 rounded-md mt-6 transition-colors cursor-pointer w-fit float-right"
        onClick={submitEmail}
      >
        {t("submit")}
      </div>
    </div>
  );
};

export default AskForOurSupport;
