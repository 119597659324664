import React from "react";
import Hero from "../components/privacyPolicy/Hero";
import Content from "../components/privacyPolicy/Content";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>EasyCrypto365 - Privacy Policy</title>
      </Helmet>
      <div>
        {/* <Hero /> */}
        <Content />
      </div>
    </>
  );
};

export default PrivacyPolicy;