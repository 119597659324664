import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";

const Footer = () => {

  const year = new Date().getFullYear();

  const ContactUs = [
    "Help",
    "Support",
    // "FAQ",
  ];
  const Resources = [
    // { resource: "Learn", link: "/"},
    // { resource: "Support", link: "/"},
    { resource: "Privacy Notice", link: "privacy-policy"},
    { resource: "Terms of Service", link: "terms"},
    { resource: "Complaints Policy", link: "complaints-policy"},
    { resource: "Cookies Policy", link: "cookie-policy"},
    { resource: "AML Policy", link: "aml-policy"}
  ];

  // const Company = [
  //   "About Us",
  //   "Newsroom",
  //   "Have Questions",
  // ];

  return (
    <div
      className="bg-[#131719] py-[54px]"
      style={{
        background: "rgba(0, 0, 0, 0.24)",
        backdropFilter: "blur(22px)",
      }}
    >
      <div className="container m-auto flex justify-between max-lg:flex-col max-lg:items-center max-lg:px-5 max-lg:py-8 max-lg:rounded-[32px] max-lg:gap-8 max-sm:p-4 max-sm:gap-10">
        {/* Left Side */}
        <div className="lg:w-[406px] flex flex-col gap-8 justify-between">
          {/* <img src="/logo.png" alt="" className="h-10" /> */}
          <Logo />
          <p className="text-[16px] font-medium text-white tracking-[-0.76px]">
          EasyCrypto365 is owned and operated by SmolEnterprises OU (16670458)- a limited liability company registered in  Tornimäe 7-26, Tallinn, 10145, Estonia holding license in Bulgaria with license number 3079325421.
          </p>
          {/* Community Section */}
          <div className="max-sm:flex-col max-sm:flex">
            <p className="text-lg font-semibold text-custom-primary mb-[12px] tracking-[-0.36px] leading-[30px]">
              Community
            </p>
            <div className="flex gap-3">
              <Link to="/">
                <img src="/icons/instagram.png" alt="" className="p-1.5 w-7 h-7 rounded-md hover:bg-[#353333]" />
              </Link>
              <Link to="/">
                <img src="/icons/facebook.png" alt="" className="p-1.5 h-7 rounded-md hover:bg-[#353333]" />
              </Link>
              <Link to="/">
                <img src="/icons/linkedin.png" alt="" className="p-1.5 w-7 h-7 rounded-md hover:bg-[#353333]" />
              </Link>
              <Link to="/">
                <img src="/icons/twitter.png" alt="" className="p-1.5 w-7 h-7 rounded-md hover:bg-[#353333]" />
              </Link>
            </div>
          </div>
        </div>
        {/* Right Side */}
        <div className="w-[614px] max-lg:w-full flex flex-col gap-[23px]">
          <div className="flex justify-evenly max-lg:justify-between max-sm:flex-col max-sm:gap-5">
            <div>
              <p className="text-lg font-semibold text-custom-primary mb-3 tracking-[-0.36px] leading-[30px]">
                Contact Us
              </p>
              {ContactUs.map((topic, index) => (
                <a key={index} href={`${index !== 1 ? 'mailto:info@easycrypto365.com' : ''}`} className="hover:decoration-white hover:underline">
                  <p className="text-white text-sm leading-[24px] mb-1.5">
                    {topic}
                  </p>
                </a>
              ))}
            </div>
            <div className="md:mr-[21px]">
              <p className="text-lg font-semibold text-custom-primary mb-[12px] tracking-[-0.36px] leading-[30px]">
                Resources
              </p>
              {Resources.map((topic, index) => (
                <Link key={index} to={topic.link} className="hover:decoration-white hover:underline">
                  <p className="text-white text-sm leading-[24px] mb-1.5">
                    {topic.resource}
                  </p>
                </Link>
              ))}
            </div>
            {/* <div className="md:mr-[40px]">
              <p className="text-lg font-semibold text-custom-primary mb-[12px] tracking-[-0.36px] leading-[30px]">
                Company
              </p>
              {Company.map((topic, index) => (
                <Link key={index} to="/" className="hover:decoration-white hover:underline">
                  <p className="text-white text-sm leading-[24px] mb-1.5">
                    {topic}
                  </p>
                </Link>
              ))}
            </div> */}
          </div>
        </div>
      </div>
      <div class="text-center text-white text-base mt-[38px] max-sm:text-[13px]">Copyright Easy Crypto 365 © {year}</div>
    </div>
  );
};

export default Footer;
