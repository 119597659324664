import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import { OpeningSoonContext } from "../context/openingSoonContext";

const Hero = () => {
  // const { setWarning } = useContext(OpeningSoonContext);
  const { t } = useTranslation();
  const [coinsData, setCoinsData] = useState([]);

  useEffect(() => {
    axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd")
      .then((response) => {
        const data = response.data;
        setCoinsData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className="h-[1080px] bg-[#141A20] rounded-[100px] relative fade-bottom">
      <div
        style={{ backgroundImage: `url("/heroBg.png")` }}
        className="h-full w-full rounded-[100px] opacity-[0.7] mix-blend-overlay"
      ></div>
      <div className="z-[10] absolute left-[50%] top-[50%] max-lg:mt-[30%] translate-x-[-50%] translate-y-[-50%] w-[100%]">
        <div className="lg:flex items-center w-[90%] md:container m-auto">
          <div className="w-full lg:w-1/2">
            <p className="text-[42px] max-sm:text-[38px] lg:text-[64px] font-semibold text-white mb-[32px] tracking-[-2.56px] max-sm:mb-4">
              Buy, Sell, Trade 
              <br/>
              with{" "}
              <span className="text-custom-primary">
                Ease
                {/* {t("home.hero.title.exchange")} */}
              </span>
            </p>
            <p className="text-base md:text-lg text-text-secondary font-light md:font-medium tracking-[-0.36px]">
              {parse(t("home.hero.description"))}
            </p>
            <div
              className="text-white text-lg bg-custom-primary hover:bg-custom-primary-dark transition-colors cursor-pointer px-5 py-4 
              rounded-lg font-semibold w-fit mt-10 max-sm:mt-5 max-sm:py-3 max-sm:px-4 max-sm:text-base"
            >
              <Link to="https://app.easycrypto365.com/login">
                {t("home.hero.button")}
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center relative mt-[74px] max-sm:mt-10">
            <img
              src="/heroImg.jpg"
              alt="hero"
              className="w-[480px] h-[574px] object-cover rounded-[20px]"
            />
            <div 
              className="flex h-fit rounded-lg bg-orange-300 bg-opacity-10 backdrop-blur-2xl p-4 gap-11 border-[#F7931A]/60 border border-solid border-gradient 
              absolute left-[-15px] md:left-0 top-[119px] floatCoins"
            >
              <div className="flex gap-3">
                <img
                  src="/icons/bitcoin.svg"
                  alt=""
                  className="h-11 w-11"
                />
                <div>
                  <p className="text-white tracking-[-0.64px]">Bitcoin</p>
                  <p className="text-white">${coinsData[0]?.current_price}</p>
                </div>
              </div>
              <div className="bg-black bg-opacity-[0.12] h-fit p-2 border-[#F7931A40] border border-solid rounded-md">
                <p className={`${(coinsData[0]?.price_change_percentage_24h < 0) ? 'text-red-500' : 'text-green-500'} leading-[15.5px] text-sm tracking-[-0.56px]`}>
                  {coinsData[0]?.price_change_percentage_24h}%
                </p>
              </div>
            </div>
            <div className="flex h-fit rounded-lg bg-[#FFFFFF] bg-opacity-10 backdrop-blur-2xl p-4 gap-11 border-[#FFFFFF]/60 border border-solid border-gradient absolute right-[-15px] md:right-0 top-[246px] floatCoins2">
              <div className="flex gap-3">
                <img
                  src="/icons/ethereum.svg"
                  alt=""
                  className="h-11 w-11"
                />
                <div>
                  <p className="text-white tracking-[-0.64px]">Ethereum</p>
                  <p className="text-white">${coinsData[1]?.current_price}</p>
                </div>
              </div>
              <div className="bg-black bg-opacity-[0.12] h-fit p-2 border-[#F7931A40] border border-solid rounded-md">
                <p className={`${(coinsData[1]?.price_change_percentage_24h < 0) ? 'text-red-500' : 'text-green-500'} leading-[15.5px] text-sm tracking-[-0.56px]`}>
                  {coinsData[1]?.price_change_percentage_24h}%
                </p>
              </div>
            </div>
            <div 
              className="flex h-fit rounded-lg bg-[#00A478] bg-opacity-10 backdrop-blur-2xl p-4 gap-11 border-[#00A478]/60 border border-solid border-gradient absolute 
              left-[32px] max-md:bottom-[-20px] md:top-[401px] floatCoins1"
            >
              <div className="flex gap-3">
                <img
                  src="/icons/tether.svg"
                  alt=""
                  className="h-11 w-11"
                />
                <div>
                  <p className="text-white tracking-[-0.64px]">USDt</p>
                  <p className="text-white">${coinsData[2]?.current_price}</p>
                </div>
              </div>
              <div className="bg-black bg-opacity-[0.12] h-fit p-2 border-[#F7931A40] border border-solid rounded-md">
                <p className={`${(coinsData[2]?.price_change_percentage_24h < 0) ? 'text-red-500' : 'text-green-500'} leading-[15.5px] text-sm tracking-[-0.56px]`}>
                  {coinsData[2]?.price_change_percentage_24h}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="fade-overlay"></div>
    </div>
  );
};

export default Hero;
