import React, { useRef } from "react";
import Slider from "react-slick";
import CarouselItem from "./CarouselItem";
import { carouselData } from "../../lib/data/homePageData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Carousel = () => {
  const slider = useRef(null);

  function SampleNextArrow() {
    return (
      <button
        className="w-[54px] h-[54px] rounded-full bg-gradient-to-br from-custom-primary/20 to-custom-primary-darker/20 text-custom-primary absolute top-[-50px] lg:top-[50%] xl:top-[25%] right-[40px] lg:right-[-80px] translate-y-[-50%]"
        onClick={() => slider.current.slickNext()}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  }

  function SamplePrevArrow() {
    return (
      <button
        className="w-[54px] h-[54px] rounded-full bg-gradient-to-br from-custom-primary/20 to-custom-primary-darker/20 text-custom-primary absolute top-[-50px] lg:top-[50%] xl:top-[25%] max-lg:right-[100px] lg:left-[-80px] translate-y-[-50%]"
        onClick={() => slider.current.slickPrev()}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  }

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    centerPadding: true,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1509,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1267,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative max-sm:mt-[200px] max-lg:mt-[400px] w-full h-[250px]">
      <div className="lg:absolute w-full bottom-0 max-lg:px-0">
        <div className="md:container m-auto relative xl:mt-32 max-xl:mb-20 max-lg:mb-0">
          <div className="w-[90%] relative m-auto">
            <Slider {...settings} ref={slider}>
              {carouselData.map((carousel) => (
                <CarouselItem carousel={carousel} />
              ))}
            </Slider>
            <SamplePrevArrow />
            <SampleNextArrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
