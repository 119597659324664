import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";

const WhyUs = () => {
  const [scrollY, setScrollY] = useState(0);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const {t} = useTranslation();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ourStats = [
    {
      title: "50k",
      desc: t("home.why.table.worldwide"),
      img: "worldwide_stats.svg"
    },
    {
      title: "10M+",
      desc: t("home.why.table.payments"),
      img: "payment.svg"
    },
    {
      title: "20+",
      desc: t("home.why.table.countries"),
      img: "country.svg"
    },
    {
      title: "100+",
      desc: t("home.why.table.currencies"),
      img: "currencies.svg"
    },
  ]

  return (
    <Parallax speed={20}>
      <div
        className={`xl:mb-[100px] max-md:mt-[50px] container ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <p className="text-[42px] lg:text-[54px] font-semibold text-white text-center">
          {t("home.why.title")}
        </p>
        <p className="text-text-desc w-[90%] md:w-[80%] lg:w-[65%] mx-auto text-center max-md:text-sm mt-2">
          {t("home.why.description")}
        </p>
        <div className="flex justify-center mt-8">
          <div 
            className="
              flex flex-col justify-center items-center gap-14 bg-gradient-to-b from-[#22262A] to-[#1A2024] p-8 rounded-2xl 
              border-[3px] border-custom-primary-dark max-md:gap-8
            "
          >
            <div className="flex gap-8 max-md:flex-col max-md:w-full">
              {ourStats.slice(0, 2).map((stat, indx) => (
                <>
                  <div className="flex gap-5 items-center max-sm:justify-start">
                    <img src={`/icons/${stat.img}`} alt="" className="w-20 h-20 max-sm:w-16 max-sm:h-16" />
                    <div className="flex flex-col">
                      <p className="bg-clip-text bg-gradient-to-l font-bold text-custom-primary text-4xl max-sm:text-3xl">
                        {stat.title}
                      </p>
                      <p className="text-base max-sm:text-sm text-white/80 font-semibold w-[200px] max-sm:w-full">
                        {stat.desc}
                      </p>
                    </div>
                  </div>
                  {indx === 0 && <div className="border border-white/10 md:hidden"></div>}
                </>
              ))}
            </div>

            <div className="border border-white/10 w-full md:hidden"></div>
            
            <div className="flex gap-8 max-md:flex-col max-md:w-full">
              {ourStats.slice(2, 4).map((stat, indx) => (
                <>
                  <div className="flex gap-5 items-center max-sm:justify-start">
                    <img src={`/icons/${stat.img}`} alt="" className="w-20 h-20 max-sm:w-16 max-sm:h-16" />
                    <div className="flex flex-col">
                      <p className="bg-clip-text bg-gradient-to-l font-bold text-custom-primary text-4xl max-sm:text-3xl">
                        {stat.title}
                      </p>
                      <p className="text-base max-sm:text-sm text-white/80 font-semibold w-[200px] max-sm:w-full">
                        {stat.desc}
                      </p>
                    </div>
                  </div>
                  {indx === 0 && <div className="border border-white/10 md:hidden"></div>}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default WhyUs;
