import { Routes, Route } from "react-router-dom";
import React from 'react'

// Pages
import Home from "../pages/Home";
import About from "../pages/About";
import Support from "../pages/Support";
import NotFound from "../pages/NotFound";
import Trade from "../pages/Trade";
import TermsAndConditions from "../pages/TermsAndConditions";
import CookiesAndPolicy from "../pages/CookiesAndPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ComplaintsPolicy from "../pages/ComplaintsPolicy";
import AML from "../pages/AML";

// Unused
import Blog from "../pages/Blog/Blog";
import BlogPost from "../pages/Blog/BlogPost";
import SingleArticle from "../pages/Article/SingleArticle";
import MoreArticles from "../pages/Article/MoreArticles";
import CommingSoon from "../pages/CommingSoon";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="support" element={<Support />} />
          <Route path="trade" element={<Trade />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="cookie-policy" element={<CookiesAndPolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="complaints-policy" element={<ComplaintsPolicy />} />
          <Route path="aml-policy" element={<AML />} />

          {/* <Route path="blog" element={<Blog />} />
          <Route path="blogPost/:slug" element={<BlogPost />} />
          <Route path="support/:slug" element={<SingleArticle />} />
          <Route path="support/more/:category" element={<MoreArticles />} />
          <Route path="underMaintenance" element={<CommingSoon />} /> */}

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  )
}

export default Router
