import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../Logo";
// import LanguageSelector from "./LanguageSelector";

const MobileNavbar = ({ open, setOpen }) => {
  const location = useLocation();
  const [closed, setClosed] = useState(false);
  const menuRef = useRef();

  const { pathname } = location;

  const year = new Date().getFullYear();

  const closeMobile = () => {
    setClosed(true);
    setTimeout(() => {
      setOpen(false);
      setClosed(false);
    }, 1000);
  };

  useEffect(() => {
    let closeMenu = (e) => {
      if(menuRef.current.contains(e.target)){
        closeMobile();
      }
    };

    document.addEventListener("mousedown", closeMenu);
    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  });

  return (
    <div className={`navbar-menu relative z-50 ${!open && "hidden"} `}>
      <div
        ref={menuRef}
        className={`navbar-backdrop fixed inset-0 bg-gray-800 opacity-25 ${
          closed && "mobileNavbBgFadeout"
        }`}
      ></div>
      <nav
        className={`fixed top-0 left-0 bottom-0 flex flex-col w-full max-w-sm py-6 px-6 bg-gradient-to-b from-[#0f151c] to-[#151A1C] overflow-y-auto ${
          closed ? "mobileNavAnimation2" : "mobileNavAnimation"
        }`}
      >
        <div className="flex justify-between items-center mb-8">
          <div onClick={closeMobile}>
            <Logo height='true'/>
          </div>
          <button className="navbar-close" onClick={closeMobile}>
            <svg
              className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div>
          <ul>
            <li className="mb-1">
              <Link
                className={`block p-4 text-base font-semibold text-gray-400 hover:bg-[#19232e7e] hover:text-custom-primary rounded`}
                to="https://app.easycrypto365.com"
              >
                Buy Crypto
              </Link>
            </li>
            <li className="mb-1">
              <Link
                onClick={closeMobile}
                className={`block p-4 text-base font-semibold ${
                  pathname === "/" ? "text-custom-primary" : "text-gray-400"
                } hover:bg-[#19232e7e] hover:text-custom-primary rounded`}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="mb-1">
              <Link
                onClick={closeMobile}
                className={`block p-4 text-base font-semibold ${
                  pathname === "/trade" ? "text-custom-primary" : "text-gray-400"
                } hover:bg-[#19232e7e] hover:text-custom-primary rounded`}
                to="/trade"
              >
                Trade
              </Link>
            </li>
            <li className="mb-1">
              <Link
                onClick={closeMobile}
                className={`block p-4 text-base font-semibold ${
                  pathname === "/about" ? "text-custom-primary" : "text-gray-400"
                } hover:bg-[#19232e7e] hover:text-custom-primary rounded`}
                to="/about"
              >
                About
              </Link>
            </li>
          </ul>
          {/* <div className="pl-4 w-fit">
            <LanguageSelector />
          </div> */}
        </div>
        <div className="mt-auto">
          <div className="pt-6">
            <a
              className="block px-4 py-3 mb-2 leading-loose text-base text-center text-white font-semibold rounded-xl"
              href="https://app.easycrypto365.com/login"
              rel="noreferrer"
            >
              Sign In
            </a>
            <a
              className="block px-4 py-3 mb-2 leading-loose text-base text-center text-black font-semibold bg-custom-primary hover:bg-custom-primary-dark  rounded-xl"
              href="https://app.easycrypto365.com/register"
              rel="noreferrer"
            >
              Sign Up
            </a>
          </div>
          <p className="my-4 text-xs text-center text-gray-400">
            <span>Copyright Easy Crypto 365 © {year}</span>
          </p>
        </div>
      </nav>
    </div>
  );
};

export default MobileNavbar;
