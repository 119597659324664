import React from "react";
import Hero from "../components/cookiesAndPolicy/Hero";
import Content from "../components/cookiesAndPolicy/Content";
import { Helmet } from "react-helmet";

const CookiesAndPolicy = () => {
  return (
    <>
      <Helmet>
        <title>EasyCrypto365 - Cookies Policy</title>
      </Helmet>
      <div>
        {/* <Hero /> */}
        <Content />
      </div>
    </>
  );
};

export default CookiesAndPolicy;