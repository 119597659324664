import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const ProductSection = () => {
  const [scrollY, setScrollY] = useState(0);

  const {t} = useTranslation()

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Parallax speed={40}>
      <div className={`sm:mb-[60px] md:mb-[300px] md:mt-[100px]`}>
        <div
          className={`container m-auto max-md:p-6 ${
            inView ? "fadeIn" : "opacity-0"
          }`}
          ref={ref}
        >
          <div className="md:flex items-center">
            <div className="w-full md:w-1/2 mr-8">
              <p className="text-white text-5xl lg:text-[54px] leading-[60px] font-semibold mb-6 max-sm:text-4xl max-sm:mb-4">
                {parse(t("home.product.title"))}
              </p>
              <p className="text-text-desc text-base max-sm:text-sm max-lg:font-light mb-8 max-sm:mb-4">
                {parse(t("home.product.description"))}
              </p>
              <div className="flex gap-5 max-sm:flex-col max-sm:items-center">
                {[0,1].map((indx)=>(
                  <div 
                    key={indx} 
                    className={`
                      w-56 h-42 flex flex-col gap-4 pb-4 justify-between bg-[#22262A] shadow-lg p-4 rounded-sm border-t-4 
                      ${indx === 0 ? 'border-[#f6bc25]' : 'border-[#385b9e]'}
                    `}
                  >
                    <div className="flex flex-col gap-2">
                      <p className="text-white">
                        {t(`home.product.list.${indx}`)}
                      </p>
                      <p className="text-xs text-white/30">{t(`home.product.listDesc.${indx}`)}</p>
                    </div>
                    <div className="w-full flex justify-end">
                      <img src={`${indx === 0 ? '/icons/SEPA.svg' : '/icons/SWIFT.png'}`} alt="" className="w-10" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full md:w-1/2 max-md:mt-8 max-sm:mt-4">
              <img src="/webView.jpg" alt="" className="rounded-[20px]"/>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default ProductSection;
