import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser"

const CallToAction = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        bg-gradient-to-br from-custom-primary to-custom-primary-darker container m-auto rounded-[20px] flex gap-[73px] md:h-[491px] h-fit 
        max-md:w-[90%] relative mb-[150px] md:mb-[400px] justify-center
      `}
    >
      <div className="max-lg:hidden w-1/3">
        <img
          src="/Laptop.png"
          alt=""
          loading="lazy"
          className="absolute max-w-xl z-[2] top-16 left-6 max-2xl:top-28 max-2xl:max-w-md max-xl:max-w-xs max-xl:left-10 max-xl:top-32"
        />
      </div>
      <div className="flex flex-col gap-6 py-16 justify-evenly max-lg:px-6 max-sm:py-10">
        <div className="flex flex-col gap-6 max-sm:gap-3">
          <p className="text-3xl sm:text-4xl md:text-5xl font-bold !leading-[1.2] z-[2] max-w-2xl">
            {parse(t("callToAction.title"))}
          </p>
          <p className="text-lg font-medium z-[2] max-sm:text-base">
            {parse(t("callToAction.description"))}
          </p>
        </div>
        <div className="flex max-md:flex-col gap-2.5 z-[2]">
          <Link to="https://app.easycrypto365.com/register" className="max-lg:w-full">
            <div
              className="
              bg-custom-secondary hover:bg-custom-secondary-dark text-white text-center py-3 px-6 rounded-lg font-semibold 
                transition-colors max-sm:text-sm mt-6 lg:max-w-[250px] w-full mx-auto
              "
            >
              Get Started
            </div>
          </Link>
        </div>
      </div>
      <img
        src="/ctoPattern.png"
        alt=""
        className="absolute z-[1] rounded-[20px] right-0 mix-blend-soft-light container h-full object-cover"
      />
    </div>
  );
};

export default CallToAction;
