import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const StartNow = () => {
  const [scrollY, setScrollY] = useState(0);

  const {t} = useTranslation();

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Parallax speed={30}>
      <div
        className={`mb-[20px] sm:mb-[50px] md:mb-[150px] ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <div className="max-md:w-[90%] max-md:m-auto max-sm:space-y-2">
          <p
            className="text-[42px] lg:text-[54px] font-semibold text-center text-white max-sm:text-4xl"
          >
            {t("home.getStarted.title")}
          </p>
          <p
            className="text-text-desc text-center w-full max-w-[600px] m-auto text-base max-sm:text-sm max-sm:mt-2 max-sm:px-3"
          >
            {parse(t("home.getStarted.description"))}
          </p>
        </div>
        <div className="flex justify-center mt-[46px]">
          <div className="flex max-xl:flex-col gap-8 max-sm:w-[90%] max-sm:py-[50px]">
            <div className="md:w-[300px] flex flex-col items-center justify-center">
              <img
                src="/icons/userCreate.svg"
                alt=""
                className="pb-2 w-20"
              />
              <p
                className="text-center text-custom-primary-light font-semibold pb-3"
              >
                {t("home.getStarted.create.name")}
              </p>
              <p
                className="text-center text-base text-white/80 font-normal w-full max-w-[250px]"
              >
                {t("home.getStarted.create.description")}
              </p>
            </div>
            <div className="h-full w-[2px] max-xl:h-px max-xl:w-full bg-[#939393]"></div>
            <div className="md:w-[300px] flex flex-col items-center justify-center">
              <img
                src="/icons/verifyAccount.svg"
                alt=""
                className="pb-2 w-20"
              />
              <p
                className="text-center text-custom-primary-light font-semibold pb-3"
              >
                {t("home.getStarted.verify.name")}
              </p>
              <p
                className="text-center text-base text-white/80 font-normal w-full max-w-[250px]"
              >
                {t("home.getStarted.verify.description")}
              </p>
            </div>
            <div className="h-full w-[2px] max-xl:h-px max-xl:w-full bg-[#939393]"></div>
            <div className="md:w-[300px] flex flex-col items-center justify-center">
              <img src="/icons/deposit.svg" alt="" className="pb-2 w-20" />
              <p
                className="text-center text-custom-primary-light font-semibold pb-3"
              >
                {t("home.getStarted.deposit.name")}
              </p>
              <p
                className="text-center text-base text-white/80 font-normal w-full max-w-[250px]"
              >
                {t("home.getStarted.deposit.description")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <a href="https://app.easycrypto365.com/login" className="flex justify-center w-full max-w-[300px]">
            <div class="
              border-custom-primary border-[1px] text-white hover:bg-custom-primary/80 hover:border-transparent cursor-pointer transition-colors hover:text-black
              px-6 py-3 rounded-lg text-base mt-10 font-semibold max-sm:py-2 max-sm:px-4 text-center w-full"
            >
              Sign Up
            </div>
          </a>
        </div>
      </div>
    </Parallax>
  );
};

export default StartNow;
