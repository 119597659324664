import React from 'react'
import Navbar from './Navbar'
import ActionBar from '../bar/ActionBar'


const Navigation = () => {


  return (
    <div className="fixed top-0 left-0 w-full z-[10000]">
      {/* <ActionBar /> */}
      <Navbar />
    </div>
  )
}

export default Navigation
