import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import SelectModal from "../components/common/SelectModal";
import Bonuses from "../components/trade/Bonuses";
import Details from "../components/trade/Details";

const Trade = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState("buy");
  const [selectModal, setSelectModal] = useState(false);
  const [getSelected, setGetSelected] = useState("EUR");
  const [sellSelected, setSellSelected] = useState("btc");

  const [coinsData, setCoinsData] = useState([]);

  const [payValue, setPayValue] = useState();
  const [getValue, setGetValue] = useState();

  const [payValueSell, setPayValueSell] = useState();
  const [getValueSell, setGetValueSell] = useState();

  const ChangePayValue = event => {
    setPayValue(event.target.value);
    const totalValue = event.target.value / coinsData[getCoinId(sellSelected)]?.current_price;
    setGetValue(totalValue.toFixed(5));
  }

  const ChangePayValueSell = event => {
    setPayValueSell(event.target.value);
    const totalValue = event.target.value * coinsData[getCoinId(sellSelected)]?.current_price;
    setGetValueSell(totalValue);
  }

  const getCoinId = (id) => {
    switch(id) {
      case 'btc':
        return '0';
      case 'eth':
        return '1';
      case 'usdt':
        return '2';
      case 'bnb':
        return '3';
      case 'sol':
        return '4';
      default:
        return '0';
    }
  }

  const changeTab = (value) => {
    setSelected(value);
    setPayValue(0);
    setGetValue(0);
    setPayValueSell(0);
    setGetValueSell(0);
  }

  // useEffect(() => {
  //   setPayValue(0);
  //   setGetValue(0);
  //   setPayValueSell(0);
  //   setGetValueSell(0);
  // }, [sellSelected, getSelected])

  useEffect(() => {
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=${getSelected.toLowerCase()}`)
      .then((response) => {
        const data = response.data;
        setCoinsData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [getSelected]);

  return (
    <div className="min-h-[120vh]">
      <div className="h-[120vh] bg-[#141A20]  fade-bottom max-sm:h-[100vh]">
        <div
          style={{ backgroundImage: `url("/heroBg.png")` }}
          className="h-full w-full rounded-[100px] opacity-[0.7] mix-blend-overlay"
        ></div>
        <div className="z-[10] absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="lg:flex items-center justify-center w-[90%] md:container mx-auto">
            <div className="bg-custom-secondary-light p-8 rounded-2xl border-white/20 border">
              <div>
                <div className="bg-custom-primary/20 p-2 flex gap-2 rounded-xl border border-custom-primary/70">
                  <div
                    className={`${
                      selected === "buy"
                        ? "bg-custom-primary"
                        : "bg-transparent text-white"
                    } py-1.5 lg:px-[98px] rounded-md text-center font-medium max-lg:flex-1 cursor-pointer max-sm:text-sm`}
                    onClick={() => changeTab("buy")}
                  >
                    {t("trade.buy")}
                  </div>
                  <div
                    className={`${
                      selected === "sell"
                        ? "bg-custom-primary"
                        : "bg-transparent text-white"
                    } py-1.5 lg:px-[98px] max-lg:flex-1 text-center rounded-md font-medium cursor-pointer max-sm:text-sm`}
                    onClick={() => changeTab("sell")}
                  >
                    {t("trade.sell")}
                  </div>
                </div>
              </div>
              <div className={`${selected !== "buy" && "hidden"}`}>
                <div className="py-1 px-3 bg-custom-primary/30 rounded-2xl w-fit mx-auto my-5">
                  <p className="text-custom-primary text-sm max-sm:text-[10px]">
                    {t("trade.estimate")} 1 BTC ≈ {coinsData[0]?.current_price} {getSelected}
                  </p>
                </div>

                <div className="mb-6">
                  <p className="text-white font-[500] text-sm pb-1.5">
                    {t("trade.pay")}
                  </p>
                  <div className="bg-[#3A3A3B] border-white/20 border py-1 sm:py-1.5 px-2 flex justify-between rounded-lg">
                    <input
                      value={payValue}
                      onChange={ChangePayValue}
                      type="number"
                      className="bg-transparent outline-none max-sm:w-[140px] sm:flex-1 px-3 py-2 text-white max-sm:text-sm"
                      placeholder="€ 0.0000"
                    />
                    <div
                      className="px-3 py-2 rounded-lg bg-white/20 text-white outline-none border-white/20 border flex items-center gap-2 cursor-pointer"
                      onClick={() => setSelectModal("get")}
                    >
                      <p className="uppercase max-sm:text-sm">{getSelected}</p>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-xs"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-white font-[500] text-sm pb-1.5">
                    {t("trade.get")}
                  </p>
                  <div className="bg-[#3A3A3B] border-white/20 border py-1 sm:py-1.5 px-2 flex justify-between rounded-lg">
                    <input
                      value={getValue}
                      type="number"
                      className="bg-transparent outline-none max-sm:w-[140px] sm:flex-1 px-3 py-1 sm:py-2 text-white max-sm:text-sm"
                      placeholder="0.0000"
                      disabled
                    />
                    <div
                      className="px-3 py-2 rounded-lg bg-white/20 text-white outline-none border-white/20 border flex items-center gap-2 cursor-pointer"
                      onClick={() => setSelectModal("sell")}
                    >
                      <p className="uppercase max-sm:text-sm">{sellSelected}</p>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-xs"
                      />
                    </div>
                  </div>
                </div>

                <Link to="https://app.easycrypto365.com/login">
                  <div className="bg-custom-primary font-semibold hover:bg-custom-primary-dark transition-colors cursor-pointer py-3 px-5 rounded-lg text-center 
                                  text-lg mt-6 max-sm:text-sm text-sm:py-2 max-sm:px-4"
                  >
                    {t("trade.buyCrypto")}
                  </div>
                </Link>
              </div>
              <div className={`${selected !== "sell" && "hidden"}`}>
                <div className="py-1 px-3 bg-custom-primary/30 rounded-2xl w-fit mx-auto my-5">
                  <p className="text-custom-primary text-sm max-sm:text-[10px]">
                    {t("trade.estimate")} 1 BTC ≈ {coinsData[0]?.current_price} {getSelected}
                  </p>
                </div>

                <div className="mb-6">
                  <p className="text-white font-medium text-sm pb-1.5">
                    {t("trade.pay")}
                  </p>
                  <div className="bg-[#3A3A3B] border-white/20 border py-1 sm:py-1.5 px-2 flex justify-between rounded-lg">
                    <input
                      value={payValueSell}
                      onChange={ChangePayValueSell}
                      type="number"
                      className="bg-transparent outline-none max-sm:w-[140px] sm:flex-1 px-3 py-2 text-white max-sm:text-sm"
                      placeholder="0.0000"
                    />
                    <div
                      className="px-3 py-2 rounded-lg bg-white/20 text-white outline-none border-white/20 border flex items-center gap-2 cursor-pointer"
                      onClick={() => setSelectModal("sell")}
                    >
                      <p className="uppercase max-sm:text-sm">{sellSelected}</p>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-xs"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-white font-[500] text-sm pb-1.5">
                    {t("trade.get")}
                  </p>
                  <div className="bg-[#3A3A3B] border-white/20 border py-1 sm:py-1.5 px-2 flex justify-between rounded-lg">
                    <input
                      value={getValueSell}
                      type="number"
                      className="bg-transparent outline-none max-sm:w-[140px] sm:flex-1 px-3 py-2 text-white max-sm:text-sm"
                      placeholder="€ 0.0000"
                      disabled
                    />
                    <div
                      className="px-3 py-2 rounded-lg bg-white/20 text-white outline-none border-white/20 border flex items-center gap-2 cursor-pointer"
                      onClick={() => setSelectModal("get")}
                    >
                      <p className="uppercase max-sm:text-sm">{getSelected}</p>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-xs"
                      />
                    </div>
                  </div>
                </div>

                <Link to="https://app.easycrypto365.com/login">
                  <div className="bg-custom-primary font-semibold hover:bg-custom-primary-dark transition-colors cursor-pointer py-3 px-5 rounded-lg 
                                  text-center text-lg mt-6 max-sm:text-sm text-sm:py-2 max-sm:px-4"
                  >
                    {t("trade.sellCrypto")}
                  </div>
                </Link>
              </div>
              {selectModal ? (
                <SelectModal
                  selectedModal={selectModal}
                  setSelectModal={setSelectModal}
                  setSellSelected={setSellSelected}
                  setGetSelected={setGetSelected}
                  sellSelected={sellSelected}
                  getSelected={getSelected}
                  coinsData={coinsData}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Details />
      <Bonuses />
    </div>
  );
};

export default Trade;
