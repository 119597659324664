import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import ArticleRowSkeleton from "../skeleton/ArticleRowSkeleton";
import useSWR from "swr";
import { fetcherGET } from "../../lib/swr/fetcher";
import { useDispatch } from "react-redux";
import { changeMessages } from "../../store/slice";
import { useTranslation } from "react-i18next";

const SelectedArticles = ({ category }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_BACKEND_URI}/articles/category/${category}`,
    fetcherGET
  );

  function ifError() {
    if (error) {
      dispatch(
        changeMessages({
          message: "Make sure your internet connection is good",
          title: "Error",
          status: "error",
        })
      );
    }
    return (
      <div>
        <ArticleRowSkeleton />
      </div>
    );
  }

  return (
    <div className="container mb-32">
      {isLoading || error ? (
        ifError
      ) : (
        <>
          <div className="mb-14">
            <p className="text-white text-xl md:text-3xl font-bold flex items-center gap-4">
              {data.articles[0].category} {t("support.articles")}{" "}
              <Link
                to={`/support/more/${category}`}
                className="text-custom-primary text-base md:text-xl ml-2 underline flex items-center gap-1"
              >
                <p>{t("more")}</p>
                <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            </p>
          </div>
          <div>
            {data.articles.map((article, indx) => (
              <div
                key={v4()}
                className={`flex items-start gap-3 py-5 ${
                  indx !== data.articles.length - 1
                    ? "border-b border-white/10"
                    : ""
                }`}
              >
                <img
                  src={`/icons/${article.category
                    .replace(" ", "_")
                    .toLowerCase()}_article.svg`}
                  alt={article.category}
                  className="w-[30px] md:w-[35px]"
                />
                <div>
                  <p className="text-white max-md:text-sm">{article.title}</p>
                  <Link to={article._id}>
                    <p className="flex items-center gap-1 text-custom-primary hover:text-custom-primary-dark transition-colors cursor-pointer max-md:text-sm">
                      {t("support.readArticle")}{" "}
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="text-[10px]"
                      />
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedArticles;
