import React from 'react'
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex justify-center mt-28 h-[70vh] max-md:flex-col max-md:gap-10 max-md:items-center'>
        <div className="flex flex-col items-start justify-center gap-4 text-white max-md:items-center max-md:px-6">
          <p className='text-6xl font-semibold max-md:text-4xl'>Page not Found...</p>
          <p className='text-white/80 max-md:text-sm max-md:text-center  max-w-md'>We can't find the page you are looking for. Check out our Help Center or head back to home.</p>
          {/* <p className='text-sm underline cursor-pointer text-white/90 hover:text-white'><Link to="/">Back to Home</Link></p> */}
          <div className='flex gap-3 mt-4 max-sm:mt-2'>
            <a 
              href="mailto:info@easycrypto365.com" 
              className='
                flex items-center bg-white text-[#0f151c] text-sm py-2 px-9 cursor-pointer
                font-semibold hover:bg-white/90 rounded
              '
            >
              Help
            </a>
            <Link to="/" className='border-2 border-white text-white text-sm py-2 px-8 cursor-pointer rounded hover:bg-[#192129]'>Home</Link>
          </div>
        </div>
        <img src="/icons/question_mark.svg" alt="Question Mark" className="w-60 max-md:w-36" />
    </div>
  )
}

export default NotFound;
