import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import useSWR from "swr";
import { fetcherGET } from "../../lib/swr/fetcher";
import ArticleSkeleton from "../skeleton/ArticleSkeleton";
import { useDispatch } from "react-redux";
import { changeMessages } from "../../store/slice";
import { useTranslation } from "react-i18next";

const TopFAQ = ({ setCategoryChosen }) => {
  const dispatch = useDispatch();

  const {t} = useTranslation()

  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_BACKEND_URI}/articles/get-top-categories`,
    fetcherGET
  );

  function ifError() {
    if (error) {
      dispatch(
        changeMessages({
          message: "Make sure your internet connection is good",
          title: "Error",
          status: "error",
        })
      );
    }
    return [0, 1, 2, 3].map((indx) => <ArticleSkeleton />);
  }

  return (
    <div className="my-36 container">
      <div className="mb-14">
        <p className="text-white text-xl md:text-3xl font-bold flex items-center gap-4">
          {t("support.topFaq")}{" "}
          {/* <Link
            to="/support/trending"
            className="text-custom-primary text-base md:text-xl ml-2 underline flex items-center gap-1"
          >
            <p>more</p>
            <FontAwesomeIcon icon={faAngleRight} />
          </Link> */}
        </p>
      </div>
      <div className="flex md:justify-between flex-wrap items-center centerCards">
        {isLoading || error
          ? ifError()
          : data.map((category) => (
              <div
                onClick={() => setCategoryChosen(category)}
                className="bg-slate-500/30 w-[300px] flex flex-col justify-center items-center py-16 border-white/40 border-[1px] text-white cursor-pointer hover:bg-slate-500/60 transition-colors rounded-md mb-10"
              >
                <img
                  src={`/icons/${category
                    .replace(" ", "_")
                    .toLowerCase()}_article.svg`}
                  alt=""
                  className="w-14 h-14 mb-2"
                />
                <p>{category}</p>
                <p className="text-custom-primary">
                {t("support.browseArticles")} <FontAwesomeIcon icon={faAngleRight} />
                </p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default TopFAQ;
