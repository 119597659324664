import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const ImageText = ({ content, title, image, side="left" }) => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto lg:flex justify-between lg:h-[460px] my-20">

      {side === "left" ? (
        <img loading="lazy" src={image} alt={image} className="w-full lg:w-[48%] rounded-xl object-cover" />
      ) : null}

      <div className={`w-full lg:w-[48%] flex flex-col ${side === "left" ? "max-lg:mt-5" : ""}`}>
        <p className="text-5xl font-semibold text-white mb-4 max-sm:text-3xl">{t(title)}</p>
        <div className="flex-1 flex flex-col justify-center max-sm:text-sm">
            <p className="text-text-secondary mb-4">
              {parse(t(content))}
            </p>
        </div>
      </div>

      {side === "right" ? (
        <img src={image} alt={image} className="w-full lg:w-[48%] rounded-xl object-cover" />
      ) : null}

    </div>
  );
};

export default ImageText;
