import React from "react";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="h-[750px] bg-[#141A20] relative fade-bottom">
      <div
        style={{ backgroundImage: `url("/heroBg.png")` }}
        className="h-full w-full opacity-[0.7] mix-blend-overlay"
      ></div>
      <div className="z-[10] absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="lg:flex items-center w-[90%] md:container m-auto">
          <div className="container flex justify-between mt-[170px] items-center max-lg:flex-col max-lg:gap-8 max-lg:mt-[100px] max-md:mt-[150px]">
            <div>
              <p className="text-5xl font-semibold text-white mb-4 max-sm:text-3xl max-sm:mb-3">
                {t("about.hero.title")}
              </p>
              <p className="text-text-secondary text-base md:text-lg max-sm:text-base">
                {t("about.hero.description")}
              </p>
              <div className="bg-custom-primary hover:bg-custom-primary-dark transition-colors cursor-pointer text-white w-fit py-3 px-5 rounded-lg mt-8 font-semibold">
                <a href="mailto:info@easycrypto365.com">{t("about.hero.contact")}</a>
              </div>
            </div>
            <div>
              <img src="/person.png" className="h-[400px] max-md:h-[250px]" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
