import React, { useState } from "react";
import Hero from "../components/support/Hero";
import TopFAQ from "../components/support/TopFAQ";
import Articles from "../components/support/Articles";
import SelectedArticles from "../components/support/SelectedArticles";
import AskForOurSupport from "../components/support/AskForOurSupport";

const Support = () => {
  const [categoryChosen, setCategoryChosen] = useState(false);
  return (
    <div>
      <Hero />
      <TopFAQ setCategoryChosen={setCategoryChosen} />
      {categoryChosen ? <SelectedArticles category={categoryChosen} /> : null}
      <Articles />
      <AskForOurSupport />
    </div>
  );
};

export default Support;
