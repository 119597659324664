import React, { useState, useEffect } from "react";
import axios from "axios";

const Details = () => {
  const [coinsData, setCoinsData] = useState([]);
  const [coinSelected, setCoinSelected] = useState(0);

  useEffect(() => {
    axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd")
      .then((response) => {
        const data = response.data;
        setCoinsData(data);
        console.log(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const Titles = ["Popularity", "Market Cap", "Volume", "Circulating Supply"];
  const Description = [`#${coinsData[coinSelected]?.market_cap_rank}`, `${coinsData[coinSelected]?.market_cap}`, `${coinsData[coinSelected]?.total_volume}`, `${coinsData[coinSelected]?.circulating_supply.toFixed(2)}`];
  const Cryptos = [`${coinsData[0]?.name}`, `${coinsData[1]?.name}`, `${coinsData[2]?.name}`, `${coinsData[3]?.name}`, `${coinsData[4]?.name}`, `${coinsData[5]?.name}`, `${coinsData[6]?.name}`]

  return (
    <div className="my-36 relative container py-[77px] px-[16px]">
      <div className="lg:flex items-center flex-col justify-center mx-auto gap-6">

        <div className="text-center mb-[50px] max-sm:mb-[30px]">
          <p className="text-white font-semibold text-[42px] max-sm:text-[36px] max-sm:mb-[12px] mb-6 max-sm:leading-[35px] leading-[52px] tracking-[-1.68px]">
            {coinsData[coinSelected]?.name} Details
          </p>
          <p className="text-[#98A2B3] max-w-[985px] m-auto text-[18px] max-sm:text-[15px] max-sm:leading-[23px] max-sm:tracking-[-0.10px] leading-[30px] tracking-[-0.20px]">
            Bitcoin's value has declined this week to ${coinsData[coinSelected]?.current_price} per {coinsData[coinSelected]?.symbol},
            resulting in a total market capitalization of {coinsData[coinSelected]?.market_cap} with
            {" "}{coinsData[coinSelected]?.circulating_supply} {coinsData[coinSelected]?.symbol} in circulation. Over the last 24 hours, trading
            volume increased by a {coinsData[coinSelected]?.price_change_percentage_24h}% change, with {coinsData[coinSelected]?.market_cap_change_24h} worth of{" "}
            {coinsData[coinSelected]?.symbol} traded.
          </p>
        </div>

        <div className="flex items-center gap-6 max-xl:flex-col">

          <div className="lg:w-full">
            <div className="flex justify-end gap-6 mb-6 max-xl:justify-center max-sm:flex-col max-sm:items-center">
              {Titles.slice(0, 2).map((crypto, i) => (
                <div className="w-[286px] h-[190px] py-3 px-6 flex items-center justify-center flex-col rounded-[10px] gap-4 bg-[#ffffff14]">
                  <p className="text-[#D0D5DD] text-xl tracking-[-0.8px] leading-[27px]">
                    {crypto}
                  </p>
                  <p className="text-custom-primary text-[32px] font-bold leading-[32px]">
                    {Description[i]}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-6 max-xl:justify-center max-sm:flex-col max-sm:items-center">
              {Titles.slice(2, 4).map((crypto, i) => (
               <div className="w-[286px] h-[190px] py-3 px-6 flex items-center justify-center flex-col rounded-[10px] gap-4 bg-[#ffffff14]">
                <p className="text-[#D0D5DD] text-xl tracking-[-0.8px] leading-[27px]">
                  {crypto}
                </p>
                <p className="text-custom-primary text-[32px] font-bold leading-[32px]">
                  {Description[i + 2]}
                </p>
              </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col justify-between bg-[#ffffff14] min-h-[404px] py-2 rounded-[10px] max-sm:w-[286px]">
            {Cryptos.map((title, i) => (
              <div 
                className={`${coinSelected === i ? 'bg-[#a1a1a114]' : ' '} flex items-center gap-2 hover:bg-[#a1a1a114] cursor-pointer py-2 px-6 w-52 max-sm:w-full`} 
                onClick={() => setCoinSelected(i)}
              >
                <img src={coinsData[i]?.image} alt="Crypto" className="w-8 h-8" />
                <p className="text-sm text-white">{title}</p>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default Details;
