import React from 'react'
import { Link } from 'react-router-dom'

const Logo = (props) => {
  return (
    <Link to="/" className={'flex items-center gap-3 ' + (props.mobile && 'max-sm:flex-col')}>
        <img src="/logo.png" alt="Logo" className="h-10" />
        <img src="/logoText.png" alt="Logo Text" className={(props.height && 'lg:h-5 max-sm:h-5') + " h-8"} />
    </Link>
  )
}

export default Logo