import { useEffect, useState, React } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import parse from "html-react-parser";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";

const Activities = () => {

  const { t } = useTranslation();
  const [coinsData, setCoinsData] = useState([]);

  useEffect(() => {
    axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd")
      .then((response) => {
        const data = response.data;
        setCoinsData(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className="max-lg:mt-[400px] mb-[150px] max-sm:mt-[250px] max-sm:mb-[100px]">
      <p className="text-[54px] font-semibold text-center text-[#d6d6d6] tracking-[-2.16px] max-sm:text-4xl max-sm:px-3">
        {t("home.cyptoTable.title")}
      </p>
      <p className="text-text-desc text-center text-base pb-14 max-sm:text-sm max-sm:mt-2 max-sm:px-3">
        {parse(t("home.cyptoTable.description"))}
      </p>
      <div
        className="select-none"
        style={{
          paddingBottom: "30px",
          position: "relative",
        }}
      >
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className="carouselTrack"
          // dotListClass="carouselDots"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 4,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
         {coinsData.slice(0, 10).map((coin, indx) => (
            <div key={indx} className="flex flex-col gap-2 bg-[#1d232a] p-4 rounded-lg">
              <div className="flex flex-col gap-4 items-center">
                <div className="flex items-center gap-2">
                  <img
                    src={`${coin?.image}`}
                    alt=""
                    className="w-[32px] h-[32px]"
                  />
                  <div>
                    <p className="text-sm md:text-base font-semibold text-white">
                      {coin?.name}
                    </p>
                    <p className="text-sm text-secondary-string">
                      {coin?.symbol.toUpperCase()}
                    </p>
                  </div>
                </div>
                <div className="flex gap-4 items-baseline">
                  <div class="text-[12px] sm:text-[13px] md:text-[15px] font-normal text-white whitespace-nowrap text-right flex flex-col items-center">
                    <p>${coin?.current_price}</p>
                    <p className="text-[10px] text-secondary-string">Current Price</p>
                  </div>
                  <div class={`${(coin?.price_change_percentage_24h < 0) ? 'text-red-500' : 'text-green-500'} text-xs flex flex-col items-center sm:text-[13px] md:text-[15px] font-[400] whitespace-nowrap text-right`}>
                    <p>{coin?.price_change_percentage_24h}%</p>
                    <p className="text-[10px] text-secondary-string">Price Change</p>
                  </div>
                  <div class="text-[12px] sm:text-[13px] md:text-[15px] font-normal text-white whitespace-nowrap text-right flex flex-col items-center">
                    <p>${coin?.market_cap}</p>
                    <p className="text-[10px] text-secondary-string">Market Cap</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Activities;