export const complaints = [
    {
      title: "1. INTRODUCTION",
      description: `EasyCrypto365 is owned and operated by SmolEnterprises OU (16670458)- a limited liability company registered in Tornimäe 7-26, Tallinn, 10145, Estonia holding license in Bulgaria with license number 3079325421. 
      <br/><br/>
      This Policy outlines how we handle any complaints that we receive and complies with our regulatory requirements.
      `
    },
    {
      title: "2. HOW TO MAKE A COMPLAINT",
      description: `If you are a client of SmolEnterprises OU, we would encourage you first to speak with the Customer Support team. If you have a complaint, the best way to contact us is via the web browser chat. Alternatively, you can submit a complaint via our online “Submit a complaint” form or by sending an email at <a className="underline" href="mailto:info@easycrypto365.com">info@easycrypto365.com</a>
      <br/><br/>
      If you choose to send an email, you’ll need to tell us:
      <ul className="list-disc list-inside">
        <li>your name and surname;</li>
        <li>the phone number and email address associated with your account;</li>
        <li>what the issue is;</li>
        <li>when the problem arose; and</li>
        <li>how you'd like us to put the matter right;</li>
      </ul>
      `
    },
    {
      title: "3. WHAT HAPPENS NEXT",
      description: `Once you’ve submitted your complaint, we’ll acknowledge receipt of this shortly after. We'll then investigate your complaint and respond to you via email.
      <br/><br/>
      Your complaint will be logged in our system and a designated Customer Support team member will be allocated to handling your matter.
      <br/><br/>
      We would like to assure you that the team member handling your complaint will be an experienced member of staff and, where appropriate, someone who was not directly involved in the matter about which you are raising a complaint. They will have the authority to settle your complaint or will have access to someone who has such authority.
      `
    },
    {
      title: "4. STEPS TOWARDS RESOLVING YOUR COMPLAINT",
      description: `Step 1 – The designated team member will acknowledge receipt of your complaint, by email, within 2 business days.
      <br/><br/>
      Step 2 – We will carry out a thorough investigation into your complaint, and you should get a response and an explanation.
      <br/><br/>
      Depending on the service provided, your complaint may be transferred directly to the company providing services to you. You can find a list of what products and services are offered by which entity below:
      <br/><br/>
      <table>
        <colgroup><col><col>
        </colgroup>
          <tbody>
            <tr>
                <td>
                    <p dir="ltr"><span>Legal entity</span></p>
                </td>
                <td>
                    <p dir="ltr" className="mb-3"><span>Provided services</span></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr"><span>Tompay Ltd.</span></p>
                </td>
                <td>
                    <p dir="ltr" className="mb-2"><span>Business and Personal E-Money, Current Accounts;</span></p>
                    <p dir="ltr" className="mb-2"><span>Payment Transaction and Transfer Services;</span></p>
                    <p dir="ltr" className="mb-3"><span>Currency Conversion / Currency Exchange Services</span></p>
                </td>
            </tr>
            <tr>
                <td>
                    <p dir="ltr"><span>NeuroNext ÖU</span></p>
                </td>
                <td>
                    <p dir="ltr" className="mb-2"><span>Virtual Currency Wallets;</span></p>
                    <p dir="ltr" className="mb-2"><span>Crypto-Fiat-Crypto exchange;</span></p>
                    <p dir="ltr" className="mb-2"><span>Crypto-Crypto exchange (Spot, Futures)</span></p>
                    <p dir="ltr" className="mb-3"><span>Crypto Earn;</span></p>
                </td>
            </tr>
          </tbody>
      </table>
      <br/>
      Consideration of complaints submitted to above listed companies will be carried out in accordance with the SmolEnterprises OU Complaint policy and the following policies will apply:
      <br/><br/>
      Tompay Ltd. -  https://www.tompayment.com/complaints-policy/
      <br/><br/><br/>
       
      
      Step 3 – If you are not happy with our response, you are invited to provide your comments particularly if we have omitted something that you consider of relevance to the matter.
      <br/><br/>
      Step 4 – SmolEnterprises OU will provide you with a final response of the initial complaint, confirming our position on your complaint and setting out our conclusions.
      <br/><br/>
      Step 5 - If you are not happy with our final resolution you can refer your complaint to the relevant external dispute resolution scheme. 
      `
    },
    {
      title: "5. TIME FRAMES",
      description: `We aim to resolve your complaint and issue our final conclusions within 15 business days of receiving your complaint.
      <br/><br/>
      However, in exceptional circumstances, if we cannot give you a final response within that time period, we will contact you to let you know why not and to confirm when you can expect to receive a substantive response from us.
      <br/><br/>
      In any event, we will send you a final response within 35 business days of receiving your complaint.
      <br/><br/>
      Please note that SmolEnterprises OU has a right to ask you for additional documents in support of your claim and will be expecting that such will be submitted without unreasonable delay.
      `
    },
    {
      title: "6. WHAT HAPPENS IF YOU ARE NOT SATISFIED WITH OUR RESPONSE",
      description: `If despite our best efforts, you’re still unhappy with how we dealt with your complaint, you can contact the relevant external dispute resolution scheme. The type of scheme you can contact depends on which legal entity provided you with the product or service you complained about.
      <br/><br/>
      If your complaint is about a regulated product or service offered by TomPayLtd, you can contact the UK’s Financial Ombudsman Service (FOS).
      `
    },
    {
      title: "THE FINANCIAL OMBUDSMAN SERVICE (FOS)  WILL ONLY CONSIDER COMPLAINTS ABOUT REGULATED PRODUCTS AND SERVICES.",
      description: `<br/>You must contact them within 6 months of the date of our final response. If you do not, the FOS will not have our permission to consider your complaint and will only be able to do so in exceptional circumstances.
      <br/><br/>
      To contact the Financial Ombudsman Service, a complainant is advised to write or telephone or email their situation to the Financial Ombudsman Service.
      <br/><br/>
      Further information and eligibility requirements can be found on https://www.financial-ombudsman.org.uk or you can contact the FOS via the methods below:
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>using the following numbers:</li>
      </ul>
      <br/>
      Telephone: 0300 123 9123, 0800 023 4567 or +44 20 7964 1000 (international)
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>by post:</li>
      </ul>
      <br/>
      Financial Ombudsman Service
      <br/><br/>
      Exchange Tower
      <br/><br/>
      London
      <br/><br/>
      E14 9SR
      <br/><br/>
      In certain events you may also be able to submit your complaint to the Financial Conduct Authority (“FCA”). Further details can be found on https://www.fca.org.uk/ or you can contact the FCA via the methods below:
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>using the following numbers:</li>
      </ul>
      <br/>
      Telephone: 0800 111 6768 (freephone), 0300 500 8082 (from the UK), or +44 207 066 1000 (international);
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>by post:</li>
      </ul>
      <br/>
      Financial Conduct Authority
      <br/><br/>
      25 The North Colonnade
      <br/><br/>
      London
      <br/><br/>
      E14 5HS
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>by e-mail:</li>
      </ul>
      <br/>
      <a href="mailto:consumer.queries@fca.org.uk" className="underline"><span>consumer.queries@fca.org.uk</span></a>
      <br/><br/>
      If your complaint is about a service offered by NeuroNext ÖU then you may contact the relevant authorities in Estonia.
      <br/><br/>
      You may also file a complaint on the activity of NeuroNext ÖU with the Estonian Financial Supervision Authority (EFSA): 
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>by post:</li>
      </ul>
      <br/>
      Estonian Financial Supervision  Authority
      <br/><br/>
      Sakala 4,
      <br/><br/>
      Tallinn
      <br/><br/>
      15030
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>by e-mail:</li>
      </ul>
      <br/>
      <a href="mailto:info@fi.ee" className="underline"><span>info@fi.ee</span></a>
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>using the following numbers:</li>
      </ul>
      <br/>
      Telephone: +372 668 0500 
      <br/><br/>
      Further information and eligibility requirements can be found on 
      <br/><br/>
      <a href="https://www.fi.ee/en/consumer/when-should-i-contact-finantsinspektsioon/making-complaint" className="underline">https://www.fi.ee/en/consumer/when-should-i-contact-finantsinspektsioon/making-complaint</a>
      `
    },
    {
      title: "7. COMPLAINT RECORDS",
      description: `The legislation obliges us to keep a record of each complaint received and the measures taken for its resolution. We retain these records for a minimum of 5 years from the date the complaint was received.
      <br/><br/>
      <a href="mailto:info@easycrypto365.com" className="underline">info@easycrypto365.com</a>
      `
    },
  ]