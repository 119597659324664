import React from "react";
import parse from "html-react-parser";
import { aml } from "../../lib/data/amlPolicy";

const Content = () => {
  return (
    <div className="container py-[150px]">
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-6 max-md:gap-4">
                <p className="md:text-5xl text-4xl font-semibold text-white">AML Policy</p>
                <p className="md:text-2xl text-base font-semibold text-white">ANTI-MONEY LAUNDERING AND COMBATING THE FINANCING OF TERRORISM</p>
            </div>
            <div className="h-px bg-gray-500"></div>
            {aml.map((term, indx) => (
                <div key={indx}>
                    <p className="text-white text-lg font-semibold mb-3">{term.title}</p>
                    <div className="text-text-desc">
                        {parse(term.description)}
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default Content;