import {React, useState} from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { Parallax } from "react-scroll-parallax";
// import { useContext } from "react";
// import { OpeningSoonContext } from "../../context/openingSoonContext";

const AccountTypes = () => {
  // const { setWarning } = useContext(OpeningSoonContext);
  const { t } = useTranslation();
  const [accountType, setAccountType] = useState('Personal');

  const setAccount = (account) => {
    setAccountType(account);
  }
  
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  return (
    <div className="md:mb-[100px] relative bg-white bg-opacity-5 backdrop-blur-xl">
      {/* <div class="side-light top-[-15%] lg:top-[-30%] max-sm:left-[-30%]"></div>
      <div class="side-light top-[70%] left-[40%] sm:left-[50%] lg:top-[30%]"></div> */}
      <div
        className={`container m-auto py-[52px] md:py-[80px] ${
          inView ? "fadeIn" : "opacity-0"
        }`}
        ref={ref}
      >
        <Parallax speed={30}>
          <div className="max-md:w-[90%] max-md:m-auto">
            <p
              className="text-center text-white text-4xl lg:text-5xl font-semibold tracking-[-2.16px] mb-2"
            >
              {t("home.types.title")}
            </p>
            <p class="text-text-desc text-center w-full max-w-[600px] m-auto text-base pb-8 max-sm:text-sm max-sm:mt-2 max-sm:px-3">
              {t("home.types.description")}
            </p>
          </div>
          <div className="w-[600px] m-auto max-sm:w-full max-sm:max-w-[500px]">
            <div className="flex items-center gap-4 max-sm:flex-col">
              <div 
                onClick={() => setAccount('Personal')}
                className={`
                  ${accountType === 'Personal' ? 'border-2 border-[#03bb90] !bg-[#1e2b28]' : 'border-0 bg-[#2B3035] '}
                  max-w-[290px] w-full flex items-center justify-center flex-col py-8 px-6 bg-[#2B3035] rounded-lg z-[10] max-sm:p-4 min-h-[292px]
                  hover:bg-[#1e2b28] hover:border-2 hover:border-[#03bb90] cursor-pointer max-sm:max-w-[250px]
                `}
              >
                <img src="/icons/avatar-icon.svg" alt="" className="pb-6 max-sm:pb-4 w-20 max-sm:w-14" />
                <p
                  className="text-2xl text-white font-semibold pb-2 max-sm:pb-4"
                  style={{ letterSpacing: "-0.96px" }}
                >
                  {t("home.types.personal.name")}
                </p>
                <p
                  className="text-sm text-white/50 text-center font-normal max-sm:pb-4"
                  style={{ letterSpacing: "-0.64px" }}
                >
                  {t("home.types.personal.description")}
                </p>
              </div>
              <div 
                onClick={() => setAccount('Business')}
                className={`
                  ${accountType === 'Business' ? 'border-2 border-[#03bb90] !bg-[#1e2b28]' : 'border-0 bg-[#2B3035] '}
                  max-w-[290px] w-full flex items-center justify-center flex-col py-8 px-6 bg-[#2B3035] rounded-lg z-[10] max-sm:p-4 min-h-[292px]
                  hover:bg-[#1e2b28] hover:border-2 hover:border-[#03bb90] cursor-pointer max-sm:max-w-[250px]
                `}
              >
                <img src="/icons/business-icon.svg" alt="" className="pb-6 max-sm:pb-4 w-20 max-sm:w-14" />
                <p
                  className="text-2xl text-white font-semibold pb-2 max-sm:pb-4"
                >
                  {t("home.types.business.name")}
                </p>
                <p
                  className="text-sm text-white/50 text-center font-normal max-sm:pb-4"
                >
                  {t("home.types.business.description")}
                </p>
              </div>
            </div>
            <Link to="https://app.easycrypto365.com/register">
              <div
                className="
                bg-custom-primary/30 hover:bg-custom-primary-dark/30 text-white text-center py-3 rounded-lg font-semibold 
                  transition-colors max-sm:text-sm mt-6 max-sm:max-w-[250px] mx-auto
                "
              >
                Register as {accountType}
              </div>
            </Link>
          </div>
        </Parallax>
      </div>
    </div>
  );
};

export default AccountTypes;
