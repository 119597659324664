export const policies = [
    {
      title: "1. PREAMBLE",
      description: `Administrator appreciating the trust of its Customers and realizing the importance of ensuring the confidentiality and security of Personal information of its Customers in the execution of the T&C enacts this Policy to determine the order of performing operations with confidential data when using the Website and Services. Knowing this Policy before using the Services provided by the System is mandatory for all Customers.
      `
    },
    {
      title: "2. TERMS",
      description: `In this Privacy Policy, the mentioned words and expressions have the following meanings:
      <br/><br/>
      2.1. Informational materials (Data) are any text, image, audio, video and mixed materials of informational character.
      <br/><br/>
      2.2. Confidential information - any information not related to the Public data which applicable law requires be treated as confidential.
      <br/><br/>
      2.3. Privacy - availability of information only to its owner and the persons to whom this information can and (or) must be provided by law and (or) the agreement with this person.
      <br/><br/>
      2.4. Public data – data to which the person who owns these data, provided access to the public (including by posting on any Internet website without restricting access to this data) or is not subject to a confidentiality requirement under applicable law.
      <br/><br/>
      2.5. Personal data - any information relating to an identified or identifiable natural person (personal data subject).
      <br/><br/>
      2.6. Disclosure - the act or omission of one party, which Confidential information has become known to third parties in the absence of the consent of the owner of the confidential information. The form of disclosure (oral, written, using technical means, etc.) doesn't matter.
      <br/><br/>
      2.7. Disclosure of Confidential information, transfer to third parties any information which is Confidential including leakage, plunder, loss, distortion, forgery, destruction, modification, copying, blocking of Confidential information as a result of default or improper performance of the terms of this Policy..
      `
    },
    {
      title: "3. GENERAL PROVISIONS",
      description: `3.1. Using any part of the Website and (or) using any of the Services, the Customer gives the Administrator the right to receive, store, process, use Personal data and other Informational materials of the Customer on the terms and conditions of this Privacy Policy.
      <br/><br/>
      3.2. This Privacy Policy does not govern and Administrator shall not be responsible for the receipt, storage, processing, use and disclosure of Personal information and other Informational materials of the Customer third party companies and organizations that are not in the possession or under the control of the Administrator, and individuals who are not employees of the Administrator, even if the Customer has accessed the Website.
      <br/><br/>
      3.3. The main purpose of receiving, storing, processing and using Personal, Public and other data of the Customer is to protect the interests of the Customers, to comply with the legal requirements of the applicable legislation, as well as to improve the quality of personalized services and Services provided by the Administrator to the Customer.
      <br/><br/>
      3.4. This Privacy Policy has no independent nature and is a Supplement to the T&C and does not supersede any provision of the main text of the T&C.
      `
    },
    {
      title: "4. THE COLLECTION OF INFORMATION",
      description: `4.1. The Administrator may collect and process the following Customer information: full name and surname, occupation, address, email address, telephone number, date of birth, as well as documents confirming the identity and address of the Customer, other data required by the Administrator in accordance with the company's internal policies and in fulfillment of regulatory requirements;
      <br/><br/>
      4.2. With the aim of fulfilling its legal obligations to combat fraud and money laundering, the Administrator will obtain Customer-specific information from third parties, including information about his financial history, decisions of local and bankruptcies, from credit bureaus and agencies to prevent fraud, when you open the Customers Wallet and any other time when it deems it necessary to prevent fraud and minimize their financial risks.
      <br/><br/>
      4.3. When Registering in the System and creating a Wallet, the Customer must specify its credentials, and (mandatory or optional) other data. After creating and using a Wallet, the Administrator can identify the Customer each time they use the System and every time you visit the Website.
      <br/><br/>
      4.4. The Administrator has the right to load on the Customer computers cookies (if the Customer does not itself have restricted this feature in your web browser), and to receive, save, process and use the information generated by the cookie. More details in Cookie policy.
      <br/><br/>
      4.5.When the Customer visits the Website, the Administrator automatically saves, processes and uses the Customer’s data that are not personal, such as, for example: the IP address of the Customer, data on the location of the Customer, which can be determined using his IP address, technical parameters of the Customer’s computer the presence or absence of specific software on the Customer’s computer, the settings of this software, cookies, as well as statistical information about the Customer’s activity.
      <br/><br/>
      4.6. In addition to visiting the Website by the Customer, the Customer also provides information to the Administrator when using the System (eventually) through applications for mobile devices, payment terminals, etc., when making calls and inquiries by phone, mail, e-mail and other channels, as well as the Administrator receives information about the Customer from other sources in the process of checking the information provided earlier by the Customer.
      `
    },
    {
      title: "5. USE OF INFORMATION",
      description: `The Administrator uses the information concerning the Customer at his disposal in the following ways:
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>for servicing the Wallets and providing the Services requested by them;</li>
        <br/>
        <li>for fulfillment of Orders of Customer on making Transfers of Funds and receiving Payments and performing Transactions using the Services;</li>
        <br/>
        <li>to inform Customers about changes in the Services and in the System, in the Rules and in the software, etc.;</li>
        <br/>
        <li>to fulfill the requirements of applicable law and regulations, and in particular the requirements of anti-laundering legislation and KYC procedures (“Know Your Customer”);</li>
        <br/>
        <li>to check the financial condition, identity of Customers;</li>
        <br/>
        <li>to carry out checks to prevent fraud and money laundering;</li>
        <br/>
        <li>for customer service, including answers to questions, comments and complaints;</li>
        <br/>
        <li>to increase the security of the Services;</li>
        <br/>
        <li>to present the contents of the Website (including its interactive functions) in the most efficient way for Customers and their computers;</li>
        <br/>
        <li>to provide the Services to Customer that they request or which, in the opinion of the Administrator, may interest them;</li>
        <br/>
        <li>to assist in the development, provision and improvement of System Services, as well as the content of the Website and informing Customers;</li>
        <br/>
        <li>for the internal purposes of the Administrator, such as conducting an audit, analytical data processing and assessing the quality of the Services provided, etc.</li>
      </ul>
      `
    },
    {
      title: "6. DISCLOSURE OF INFORMATION",
      description: `6.1. The Administrator agrees not to provide Personal Data to third parties for commercial purposes without the consent of the Customer to whom this data relates.
      <br/><br/>
      6.2. Transfer of Personal data to third parties is allowed in the following cases:
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>after obtaining the consent of the Customer to whom the information relates;</li>
        <br/>
        <li>at the reasonable request of government bodies entitled to receive such data;</li>
        <br/>
        <li>the provision of data to agents and third parties acting under contract with the Administrator, to fulfill obligations to the Customer (including, but not limited to, acquirers, issuers of payment cards, international card system, providers, billing or delivery companies, affiliated persons of the Administrator, etc.) and upon the conditions that:</li>
      </ul>
      <br/><br/>
      2) they maintain appropriate security measures to protect confidential information from unauthorized access or use;
      <br/><br/>
      <ul className="list-disc list-inside">
        <li>the provision of data to potential lenders, investors, acquirers, partners, shareholders, auditors of the Administrator;</li>
        <br/>
        <li>in the exchange of information with other companies and organizations for fraud protection and reduction of financial risk;</li>
        <br/>
        <li>if, in the opinion of the Administrator, the Customer violates the terms of this Privacy Policy, and (or) internal rules of the Administrator, and (or) if, in the opinion of the Administrator, such provision is necessary in order to investigate, prevent, or take action against illegal activities, suspected fraud, potential threats to the physical safety of any person, violations of conditions, Privacy Policy, internal rules of the Administrator and (or) required by law;</li>
        <br/>
        <li>in other cases, in accordance with the provisions of the Privacy Policy and applicable law.</li>
      </ul>
      <br/><br/>
      6.3. The Administrator reserves the right to disclose confidential information when required by rules of business.
      <br/><br/>
      6.4. Nothing in this Privacy Policy will not limit to disclose any Confidential formation in accordance with judicial or other decision of a legitimate statutory or regulatory obligation, if such disclosure is necessary and provided that, where possible, of the fact of such disclosure will be informed in advance.
      <br/><br/>
      6.5. All Confidential Information shall remain the property of the disclosing party, and no rights or licenses are granted in respect of such Confidential Information, except as specifically set out in this Privacy Policy.
      <br/><br/>
      6.6. All Confidential Information will be returned to the disclosing party and/or destroyed upon request, unless retention is required by any legal or regulatory obligation, and, if necessary, will certify in writing signed by an authorized person that this has been done.
      `
    },
    {
      title: "7. PROCESSING INFORMATION",
      description: `7.1. Providing his Personal data during Registration in the System, the Customer agrees to its processing both using automation tools and without using automation tools, in particular, collection, storage, transfer to third parties and use by the Administrator in order to provide Services to the Customer and for other purposes specified in the Privacy Policy.
      <br/><br/>
      7.2. If the Customer does not want his Personal data to be processed, then he must contact the Administrator for Details. In this case, all information received from the Customer is deleted from the Administrator’s client base (unless otherwise provided by applicable law) and the Customer will not be able to use the Services.
      `
    },
    {
      title: "8. ACCESS TO INFORMATION",
      description: `8.1. In order to exercise the rights of Customers, as well as the proper fulfillment by the Administrator of his obligations stipulated by the Privacy Policy and other internal politicians, the Administrator may study the Information Materials of the Customers (in order to timely resolve technical problems, suppress illegal actions that violate the rights of any of other Customers, etc. .).
      <br/><br/>
      8.2. The Administrator provides limited access to the Personal Data of Customers only to those employees who, in his opinion, really need to use this information in order to provide Customers with services, or in order to fulfill their official duties.
      <br/><br/>
      8.3. The Customer has the right to request, change or delete his Personal Data, which is located at the Administrator and (or) on the Website. The Customer can independently change or delete his Personal Data using the appropriate functions of the Website and his Wallet.
      <br/><br/>
      8.4. The applicable legislation on the protection of personal data gives the Customer the right to access information about him. Moreover, any request for access may become a reason for charging a fee to cover the costs of providing the Customer with detailed information about him stored by the Administrator and (or) third parties..
      `
    },
    {
      title: "9. PROTECTION OF INFORMATION",
      description: `9.1. Both the Administrator and the Customer guarantee the confidentiality of the information received during the execution of the requirements of the Privacy Policy, unless otherwise expressly provided for in the Privacy Policy and by applicable law.
      <br/><br/>
      9.2. The Customer, who has violated the obligation to maintain confidentiality of information, is obligated to compensate all losses (including lost profits) received by third parties and (or) the Administrator as a result of these actions of the Customer.
      <br/><br/>
      9.3. Confidentiality conditions are contained in this Privacy Policy and are accepted by the Customer in full upon acceptance of the Privacy Policy.
      <br/><br/>
      9.4. Both the Administrator and the Customer undertake NOT to disclose information received in the course of fulfilling obligations, unless it’s obliged to provide such information in accordance with applicable law.
      <br/><br/>
      9.5. Both the Administrator and the Customer shall not have the right to unilaterally terminate the protection of the confidentiality of this information provided for by this Privacy Policy, unless otherwise provided for by applicable law.
      <br/><br/>
      9.6. It is not a violation of confidentiality to provide Confidential information at the legal request of law enforcement and other authorized state bodies and (or) officials in cases and in the manner prescribed by applicable law.
      <br/><br/>
      9.7. As follows from other sections of this Privacy Policy, Customers are responsible for maintaining the confidentiality of their Authentication data. The Administrator is not responsible for transactions made as a result of improper storage or loss of this information by the Customers.`
    },
    {
      title: "10. OTHER PROVISIONS",
      description: `10.1. The Administrator has the right to save the Customer’s Personal and other data for as long as necessary, or within the time period established by applicable law, or until the Customer removes this data.
      <br/><br/>
      10.2. The Customer is responsible for providing reliable information, as well as for updating the previously provided data in case of any changes.
      <br/><br/>
      10.3. The Customer allows the Administrator to allow other persons with whom the Administrator has entered into relevant agreements to receive, save and process data about the Customer, such as the IP address of the Customer, cookies, as well as statistical information about the Customer’s activity, for improving the quality of services provided by these persons and providing information of an advertising nature.
      <br/><br/>
      10.4. The Customer agrees that the confidentiality of data transmitted via the Internet is not guaranteed if access to this data is obtained by third parties outside the area of technical communications controlled by the Administrator, and the latter is not responsible for damage caused by such access.
      <br/><br/>
      10.5. If, when using the Website and (or) the System, the Customer has somehow become aware of information regarding the Administrator and (or) third parties, which, according to applicable law, is confidential and (or) a trade secret, the Customer is prohibited from storing, using and distributing such information.
      <br/><br/>
      10.6. When you click on the links on our Website, you may end up on third-party sites. We do not bear any responsibility for the content of these third-party sites, as well as for the personal data that these sites may collect, including policies for the storage and use of such data. Third-party sites may have their own rules for working with personal information. We strongly recommend that you familiarize yourself with the information on this subject on third-party sites in order to understand what rules determine the work with your personal data. After you leave our Website, the provisions of our Privacy Policy cease to apply.`
    },
  ]
  
  
  export const policiesdsfdfs = [
    {
      title: "1. PREAMBLE",
      description: `Administrator appreciating the trust of its Customers and realizing the importance of ensuring the confidentiality and security of Personal information of its Customers in the execution of the T&C enacts this Policy to determine the order of performing operations with confidential data when using the Website and Services. Knowing this Policy before using the Services provided by the System is mandatory for all Customers.
      `
    },
    {
      title: "2. TERMS",
      description: `In this Privacy Policy, the mentioned words and expressions have the following meanings:
      <br/><br/>
      2.1. Informational materials (Data) are any text, image, audio, video and mixed materials of informational character.
      <br/><br/>
      2.2. Confidential information - any information not related to the Public data which applicable law requires be treated as confidential.
      <br/><br/>
      2.3. Privacy - availability of information only to its owner and the persons to whom this information can and (or) must be provided by law and (or) the agreement with this person.
      <br/><br/>
      2.4. Public data – data to which the person who owns these data, provided access to the public (including by posting on any Internet website without restricting access to this data) or is not subject to a confidentiality requirement under applicable law.
      <br/><br/>
      2.5. Personal data - any information relating to an identified or identifiable natural person (personal data subject).
      <br/><br/>
      2.6. Disclosure - the act or omission of one party, which Confidential information has become known to third parties in the absence of the consent of the owner of the confidential information. The form of disclosure (oral, written, using technical means, etc.) doesn't matter.
      <br/><br/>
      2.7. Disclosure of Confidential information, transfer to third parties any information which is Confidential including leakage, plunder, loss, distortion, forgery, destruction, modification, copying, blocking of Confidential information as a result of default or improper performance of the terms of this Policy..
      `
    },
    {
      title: "3. GENERAL PROVISIONS",
      description: `3.1. Using any part of the Website and (or) using any of the Services, the Customer gives the Administrator the right to receive, store, process, use Personal data and other Informational materials of the Customer on the terms and conditions of this Privacy Policy.
      <br/><br/>
      3.2. This Privacy Policy does not govern and Administrator shall not be responsible for the receipt, storage, processing, use and disclosure of Personal information and other Informational materials of the Customer third party companies and organizations that are not in the possession or under the control of the Administrator, and individuals who are not employees of the Administrator, even if the Customer has accessed the Website.
      <br/><br/>
      3.3. The main purpose of receiving, storing, processing and using Personal, Public and other data of the Customer is to protect the interests of the Customers, to comply with the legal requirements of the applicable legislation, as well as to improve the quality of personalized services and Services provided by the Administrator to the Customer.
      <br/><br/>
      3.4. This Privacy Policy has no independent nature and is a Supplement to the T&C and does not supersede any provision of the main text of the T&C.
      `
    },
    {
      title: "4. THE COLLECTION OF INFORMATION",
      description: `4.1. The Administrator may collect and process the following Customer information: full name and surname, occupation, address, email address, telephone number, date of birth, as well as documents confirming the identity and address of the Customer, other data required by the Administrator in accordance with the company's internal policies and in fulfillment of regulatory requirements;
      <br/><br/>
      4.2. With the aim of fulfilling its legal obligations to combat fraud and money laundering, the Administrator will obtain Customer-specific information from third parties, including information about his financial history, decisions of local and bankruptcies, from credit bureaus and agencies to prevent fraud, when you open the Customers Wallet and any other time when it deems it necessary to prevent fraud and minimize their financial risks.
      <br/><br/>
      4.3. When Registering in the System and creating a Wallet, the Customer must specify its credentials, and (mandatory or optional) other data. After creating and using a Wallet, the Administrator can identify the Customer each time they use the System and every time you visit the Website.
      <br/><br/>
      4.4. The Administrator has the right to load on the Customer computers cookies (if the Customer does not itself have restricted this feature in your web browser), and to receive, save, process and use the information generated by the cookie. More details in Cookie policy.
      <br/><br/>
      4.5.When the Customer visits the Website, the Administrator automatically saves, processes and uses the Customer’s data that are not personal, such as, for example: the IP address of the Customer, data on the location of the Customer, which can be determined using his IP address, technical parameters of the Customer’s computer the presence or absence of specific software on the Customer’s computer, the settings of this software, cookies, as well as statistical information about the Customer’s activity.
      <br/><br/>
      4.6. In addition to visiting the Website by the Customer, the Customer also provides information to the Administrator when using the System (eventually) through applications for mobile devices, payment terminals, etc., when making calls and inquiries by phone, mail, e-mail and other channels, as well as the Administrator receives information about the Customer from other sources in the process of checking the information provided earlier by the Customer.
      `
    },
    {
      title: "5. USE OF INFORMATION",
      description: [`The Administrator uses the information concerning the Customer at his disposal in the following ways:
      <br/><br/>`, ['for servicing the Wallets and providing the Services requested by them;', 'for fulfillment of Orders of Customer on making Transfers of Funds and receiving Payments and performing Transactions using the Services;', 'to inform Customers about changes in the Services and in the System, in the Rules and in the software, etc.;', 'to fulfill the requirements of applicable law and regulations, and in particular the requirements of anti-laundering legislation and KYC procedures (“Know Your Customer”);', 'to check the financial condition, identity of Customers;', 'to carry out checks to prevent fraud and money laundering;', 'for customer service, including answers to questions, comments and complaints;', 'to increase the security of the Services;', 'to present the contents of the Website (including its interactive functions) in the most efficient way for Customers and their computers;', 'to provide the Services to Customer that they request or which, in the opinion of the Administrator, may interest them;', 'to assist in the development, provision and improvement of System Services, as well as the content of the Website and informing Customers;', 'for the internal purposes of the Administrator, such as conducting an audit, analytical data processing and assessing the quality of the Services provided, etc.']]
    },
    {
      title: "6. DISCLOSURE OF INFORMATION",
      description: [`6.1. The Administrator agrees not to provide Personal Data to third parties for commercial purposes without the consent of the Customer to whom this data relates.
      <br/><br/>
      6.2. Transfer of Personal data to third parties is allowed in the following cases:
      <br/><br/>`, ['after obtaining the consent of the Customer to whom the information relates;', 'at the reasonable request of government bodies entitled to receive such data;', 'the provision of data to agents and third parties acting under contract with the Administrator, to fulfill obligations to the Customer (including, but not limited to, acquirers, issuers of payment cards, international card system, providers, billing or delivery companies, affiliated persons of the Administrator, etc.) and upon the conditions that:'], `
      <br/><br/>
      2) they maintain appropriate security measures to protect confidential information from unauthorized access or use;
      <br/><br/>`, ['the provision of data to potential lenders, investors, acquirers, partners, shareholders, auditors of the Administrator;', 'in the exchange of information with other companies and organizations for fraud protection and reduction of financial risk;', 'if, in the opinion of the Administrator, the Customer violates the terms of this Privacy Policy, and (or) internal rules of the Administrator, and (or) if, in the opinion of the Administrator, such provision is necessary in order to investigate, prevent, or take action against illegal activities, suspected fraud, potential threats to the physical safety of any person, violations of conditions, Privacy Policy, internal rules of the Administrator and (or) required by law;', 'in other cases, in accordance with the provisions of the Privacy Policy and applicable law.'],
        `
      <br/><br/>
      6.3. The Administrator reserves the right to disclose confidential information when required by rules of business.
      <br/><br/>
      6.4. Nothing in this Privacy Policy will not limit to disclose any Confidential formation in accordance with judicial or other decision of a legitimate statutory or regulatory obligation, if such disclosure is necessary and provided that, where possible, of the fact of such disclosure will be informed in advance.
      <br/><br/>
      6.5. All Confidential Information shall remain the property of the disclosing party, and no rights or licenses are granted in respect of such Confidential Information, except as specifically set out in this Privacy Policy.
      <br/><br/>
      6.6. All Confidential Information will be returned to the disclosing party and/or destroyed upon request, unless retention is required by any legal or regulatory obligation, and, if necessary, will certify in writing signed by an authorized person that this has been done.
      `]
    },
    {
      title: "7. PROCESSING INFORMATION",
      description: `7.1. Providing his Personal data during Registration in the System, the Customer agrees to its processing both using automation tools and without using automation tools, in particular, collection, storage, transfer to third parties and use by the Administrator in order to provide Services to the Customer and for other purposes specified in the Privacy Policy.
      <br/><br/>
      7.2. If the Customer does not want his Personal data to be processed, then he must contact the Administrator for Details. In this case, all information received from the Customer is deleted from the Administrator’s client base (unless otherwise provided by applicable law) and the Customer will not be able to use the Services.
      `
    },
    {
      title: "8. ACCESS TO INFORMATION",
      description: `8.1. In order to exercise the rights of Customers, as well as the proper fulfillment by the Administrator of his obligations stipulated by the Privacy Policy and other internal politicians, the Administrator may study the Information Materials of the Customers (in order to timely resolve technical problems, suppress illegal actions that violate the rights of any of other Customers, etc. .).
      <br/><br/>
      8.2. The Administrator provides limited access to the Personal Data of Customers only to those employees who, in his opinion, really need to use this information in order to provide Customers with services, or in order to fulfill their official duties.
      <br/><br/>
      8.3. The Customer has the right to request, change or delete his Personal Data, which is located at the Administrator and (or) on the Website. The Customer can independently change or delete his Personal Data using the appropriate functions of the Website and his Wallet.
      <br/><br/>
      8.4. The applicable legislation on the protection of personal data gives the Customer the right to access information about him. Moreover, any request for access may become a reason for charging a fee to cover the costs of providing the Customer with detailed information about him stored by the Administrator and (or) third parties..
      `
    },
    {
      title: "9. PROTECTION OF INFORMATION",
      description: `9.1. Both the Administrator and the Customer guarantee the confidentiality of the information received during the execution of the requirements of the Privacy Policy, unless otherwise expressly provided for in the Privacy Policy and by applicable law.
      <br/><br/>
      9.2. The Customer, who has violated the obligation to maintain confidentiality of information, is obligated to compensate all losses (including lost profits) received by third parties and (or) the Administrator as a result of these actions of the Customer.
      <br/><br/>
      9.3. Confidentiality conditions are contained in this Privacy Policy and are accepted by the Customer in full upon acceptance of the Privacy Policy.
      <br/><br/>
      9.4. Both the Administrator and the Customer undertake NOT to disclose information received in the course of fulfilling obligations, unless it’s obliged to provide such information in accordance with applicable law.
      <br/><br/>
      9.5. Both the Administrator and the Customer shall not have the right to unilaterally terminate the protection of the confidentiality of this information provided for by this Privacy Policy, unless otherwise provided for by applicable law.
      <br/><br/>
      9.6. It is not a violation of confidentiality to provide Confidential information at the legal request of law enforcement and other authorized state bodies and (or) officials in cases and in the manner prescribed by applicable law.
      <br/><br/>
      9.7. As follows from other sections of this Privacy Policy, Customers are responsible for maintaining the confidentiality of their Authentication data. The Administrator is not responsible for transactions made as a result of improper storage or loss of this information by the Customers.`
    },
    {
      title: "10. OTHER PROVISIONS",
      description: `10.1. The Administrator has the right to save the Customer’s Personal and other data for as long as necessary, or within the time period established by applicable law, or until the Customer removes this data.
      <br/><br/>
      10.2. The Customer is responsible for providing reliable information, as well as for updating the previously provided data in case of any changes.
      <br/><br/>
      10.3. The Customer allows the Administrator to allow other persons with whom the Administrator has entered into relevant agreements to receive, save and process data about the Customer, such as the IP address of the Customer, cookies, as well as statistical information about the Customer’s activity, for improving the quality of services provided by these persons and providing information of an advertising nature.
      <br/><br/>
      10.4. The Customer agrees that the confidentiality of data transmitted via the Internet is not guaranteed if access to this data is obtained by third parties outside the area of technical communications controlled by the Administrator, and the latter is not responsible for damage caused by such access.
      <br/><br/>
      10.5. If, when using the Website and (or) the System, the Customer has somehow become aware of information regarding the Administrator and (or) third parties, which, according to applicable law, is confidential and (or) a trade secret, the Customer is prohibited from storing, using and distributing such information.
      <br/><br/>
      10.6. When you click on the links on our Website, you may end up on third-party sites. We do not bear any responsibility for the content of these third-party sites, as well as for the personal data that these sites may collect, including policies for the storage and use of such data. Third-party sites may have their own rules for working with personal information. We strongly recommend that you familiarize yourself with the information on this subject on third-party sites in order to understand what rules determine the work with your personal data. After you leave our Website, the provisions of our Privacy Policy cease to apply.`
    },
  ]