import React from "react";
import parse from "html-react-parser";
import { policies } from "../../lib/data/privacyPolicy";

const Content = () => {
  return (
    <div className="container py-[150px]">
      <div className="flex flex-col gap-[42px]">
      <p className="md:text-[54px] text-4xl font-[600] text-white">Privacy Policy</p>
      <div className="h-px bg-gray-500"></div>
        {policies.map((term) => (
          <div>
            <p className="text-white text-lg font-[600] mb-3">{term.title}</p>
            <div className="text-text-desc">
              {/* {typeof term.description !== "string" ? (
                term.description.map((desc) =>
                  typeof desc === "string" ? (
                    parse(desc)
                  ) : (
                    <ul className="list-disc list-inside">
                      {desc.map((liElement) => (
                        <li>{liElement}</li>
                      ))}
                    </ul>
                  )
                )
              ) : (
              )} */}
              {parse(term.description)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Content;