import React from "react";
import { Helmet } from "react-helmet";

// Components
import Hero from "../components/Hero";
import AccountTypes from "../components/home/AccountTypes";
import WhyUs from "../components/home/WhyUs";
import StartNow from "../components/home/StartNow";
import Activities from "../components/home/Activies";
import ProductSection from "../components/home/ProductSection";
import Carousel from "../components/home/Carousel";
import CallToAction from "../components/home/CallToAction";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Easy Crypto 365</title>
      </Helmet>
      <div className="relative">
        <Hero />
        <Carousel />
        <Activities />
        <WhyUs />
        <ProductSection />
        <AccountTypes />
        <StartNow />
        <CallToAction />
      </div>
    </>
  );
};

export default Home;
