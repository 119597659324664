import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CarouselItem = ({ carousel }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex relative flex-col justify-between ${
        carousel.type === "gray"
          ? "bg-gradient-to-br from-white/10 to-white/5"
          : "bg-gradient-to-br from-custom-primary to-custom-primary-dark"
      }  backdrop-blur-[38px] px-6 py-6 gap-6 md:gap-11 border-[#ffffff]/60 border border-solid border-gradient w-[340px] min-h-[194px] rounded-xl ml-2`}
    >
      <div>
        <p className={`text-white text-xl font-semibold`}>
         {carousel.title}
        </p>
        <p className={`text-white text-sm opacity-80`}>
          {carousel.description}
        </p>
      </div>

      <Link
        to="https://app.easycrypto365.com/register"
        className={`text-white underline text-base font-semibold`}
      >
        Register Now
      </Link>
    </div>
  );
};

export default CarouselItem;
